import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from '../components/services/system/alerts/alerts.service';
import { LoaderService } from '../components/services/system/loader/loader.service';
import { makeid } from './unqueString';

@Injectable({
  providedIn: 'root'
})
export class SignatureHelper {

  constructor(private loaderService: LoaderService, private router: Router, private alertsService: AlertsService) {
  }

  public signed: boolean = false;
  public waitTimeMintues: number = 0;
  public seconds: number = 0;
  public showWaitTimeMessage: boolean = false;
  public uniqueLoaderId: string;

  counterMethodSeconds(_uniqueLoaderId) {
    const self = this;
    let intervalId = setInterval(() => {
      self.seconds = self.seconds + 1;
      self.waitTimeMintues = Math.floor(self.seconds / 60);
      if (self.seconds > 99 && self.showWaitTimeMessage) {
        self.loaderService.changeLoaderMessage('Biðtími þinn er: ' + self.waitTimeMintues.toString() + " mínútur, sem er eðlilegt fyrir stór undirritunarskjöl");
      } else if (self.showWaitTimeMessage) {
        self.loaderService.changeLoaderMessage('Biðtími þinn er: ' + self.seconds.toString() + " sekúndur, sem er eðlilegt. Vinsamlegast sýnið biðlund");
      }
      if (self.signed) {
        clearInterval(intervalId);
      }
      if (_uniqueLoaderId != self.uniqueLoaderId) {
        clearInterval(intervalId);
      }
    }, 1000);
  }

  // Passar að halda notandanum heitum þegar um stórt skjal er að ræða
  public signatureLoadingMessages(counter: boolean = true) {
    let _uniqueLoaderId = this.uniqueLoaderId;

    if (counter) {
      _uniqueLoaderId = makeid(10);
      this.uniqueLoaderId = _uniqueLoaderId;
      this.counterMethodSeconds(_uniqueLoaderId);
      // Passar að eyða loader ef notandi fer á aðra síðu
      this.router.events.subscribe((val) => {
        if (!self.signed) {
          self.signed = true;
          this.loaderService.changeActiveLoaders(-1);
        }
      });
    }
    const self = this;
    this.signed = false;
    setTimeout(
      function () {
        if (!self.signed && _uniqueLoaderId == self.uniqueLoaderId) {
          self.showWaitTimeMessage = false;
          self.loaderService.changeLoaderMessage('Undirritunarbeiðni getur tekið tíma að berast, sérstaklega í tilfellum stórra skjala. Vinsamlegast sýnið biðlund');
        }
      }, 13000);
    setTimeout(
      function () {
        if (!self.signed && _uniqueLoaderId == self.uniqueLoaderId) {
          self.showWaitTimeMessage = false;
          self.loaderService.changeLoaderMessage('Eftir því sem skjöl verða stærri tekur lengri tíma að dulrita þau með löglegri undirritun');
        }
      }, 25000);
    setTimeout(
      function () {
        if (!self.signed && _uniqueLoaderId == self.uniqueLoaderId) {
          self.showWaitTimeMessage = true;
        }
      }, 35000);
    setTimeout(
      function () {
        if (!self.signed && _uniqueLoaderId == self.uniqueLoaderId) {
          self.showWaitTimeMessage = false;
          self.loaderService.changeLoaderMessage('Þetta er eðlilegur biðtími. Vinsamlegast sýnið biðlund');
        }
      }, 45000);
    setTimeout(
      function () {
        if (!self.signed && _uniqueLoaderId == self.uniqueLoaderId) {
          self.showWaitTimeMessage = false;
          self.loaderService.changeLoaderMessage('Undirritunarbeiðnin er á leiðinni. Vinsamlegast sýnið biðlund');
        }
      }, 70000);

    setTimeout(
      function () {
        if (!self.signed && _uniqueLoaderId == self.uniqueLoaderId) {
          self.showWaitTimeMessage = false;
          self.loaderService.changeLoaderMessage('Við tryggjum örugg fasteignaviðskipti með löglegum undirritunum. Vinsamlegast sýnið biðlund');
        }
      }, 95000);
    setTimeout(
      function () {
        if (!self.signed && self.waitTimeMintues > 25 && _uniqueLoaderId == self.uniqueLoaderId) {
          self.showWaitTimeMessage = false;
          self.alertsService.changealertHeaderMessageFooter("Villa kom upp", "Undirritunarbeiðni tók óeðlilega langan tíma að berast.", "Vinsamlegast sendið okkur fyrirspurn og kerfisstjóri skoðar málið");
          self.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            self.router.navigate([window.location.pathname]);
          });
        } else if (!self.signed && _uniqueLoaderId == self.uniqueLoaderId) {
          self.signatureLoadingMessages(false);
        }
      }, 120000);
  }

  public signatureLoaderInit() {
    this.signed = true;
    this.waitTimeMintues = 0;
    this.seconds = 0;
    this.showWaitTimeMessage = false;
    this.uniqueLoaderId = "";
  }
}
