import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, catchError, finalize, map, of, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { getCookie, setCookie } from 'src/app/helpers/cookies';
import { dateToGMT } from 'src/app/helpers/dateToGMT';
import { HttpError } from 'src/app/helpers/httpError';
import { HttpRequestHelper } from 'src/app/helpers/httpRequestHelper';
import { SignatureHelper } from 'src/app/helpers/signatureHelper';
import { makeid } from 'src/app/helpers/unqueString';

import { SkraFasteignCreate } from 'src/app/components/interfaces/CRUDL/create/skraFasteignCreate';
import { ImageItem } from 'src/app/components/interfaces/items/imageItem';
import { ExposedLogicKannaSkraningTilReq } from 'src/app/components/interfaces/requests/Exposed/ExposedLogicKannaSkraningTilReq';
import { ExposedSubmitVisitReq } from 'src/app/components/interfaces/requests/Exposed/ExposedSubmitVisitReqReq';
import { ExposedVistaSkraninguFasteignarReq } from 'src/app/components/interfaces/requests/Exposed/ExposedVistaSkraninguFasteignarReq/ExposedVistaSkraninguFasteignarReq';
import { ExposedBreytaVerdmatReq } from 'src/app/components/interfaces/requests/Exposed/exposedBreytaVerdmatReq';
import { ExposedCheckIfMarkedFavoriteReq } from 'src/app/components/interfaces/requests/Exposed/exposedCheckIfMarkedFavoriteReq';
import { ExposedDeleteFasteignirFavoritesReq } from 'src/app/components/interfaces/requests/Exposed/exposedDeleteFasteignirFavoritesReq';
import { ExposedFasteignInitCreateReq } from 'src/app/components/interfaces/requests/Exposed/exposedFasteignInitCreateReq';
import { ExposedFasteignInitUpdateReq } from 'src/app/components/interfaces/requests/Exposed/exposedFasteignInitUpdateReq';
import { ExposedGetSkraningaryfirlitReq } from 'src/app/components/interfaces/requests/Exposed/exposedFasteignSkraningaryfirlitReq';
import { ExposedFasteignirFavoritesReq } from 'src/app/components/interfaces/requests/Exposed/exposedFasteignirFavoritesReq';
import { ExposedFasteignirSkjolReq } from 'src/app/components/interfaces/requests/Exposed/exposedFasteignirSkjolReq';
import { ExposedFrittVerdmatReq } from 'src/app/components/interfaces/requests/Exposed/exposedFrittVerdmatReq';
import { ExposedGeneratePdfThjonustusamningurKaupendurReq } from 'src/app/components/interfaces/requests/Exposed/exposedGeneratePdfThjonustusamningurKaupendurReq';
import { ExposedGeneratePdfThjonustusamningurSeljendurReq } from 'src/app/components/interfaces/requests/Exposed/exposedGeneratePdfThjonustusamningurSeljendurReq';
import { ExposedGetDistancesReq } from 'src/app/components/interfaces/requests/Exposed/exposedGetDistancesReq';
import { ExposedGetFasteignSkraningReq } from 'src/app/components/interfaces/requests/Exposed/exposedGetFasteignSkraningReq';
import { ExposedGetLastSoluyfirlitReq } from 'src/app/components/interfaces/requests/Exposed/exposedGetLastSoluyfirlitReq';
import { ExposedGetVisitsReq } from 'src/app/components/interfaces/requests/Exposed/exposedGetVisitsReq';
import { ExposedHaettaVidOpidHusReq } from 'src/app/components/interfaces/requests/Exposed/exposedHaettaVidOpidHusReq';
import { ExposedHlutdeildarlanSubmitVaktanirReq } from 'src/app/components/interfaces/requests/Exposed/exposedHlutdeildarlanSubmitVaktanirReq';
import { ExposedListFasteignMyndirReq } from 'src/app/components/interfaces/requests/Exposed/exposedListFasteignMyndirReq';
import { ExposedListMinarFasteignaSkraningarReq } from 'src/app/components/interfaces/requests/Exposed/exposedListMinarFasteignaSkraningarReq';
import { ExposedListTilSoluEignirReq } from 'src/app/components/interfaces/requests/Exposed/exposedListTilSoluEignirReq';
import { ExposedMblEydaSkraninguReq } from 'src/app/components/interfaces/requests/Exposed/exposedMblEydaSkraninguReq';
import { ExposedMblStofnaSkraninguReq } from 'src/app/components/interfaces/requests/Exposed/exposedMblStofnaSkraninguReq';
import { ExposedReadSoluyfirlitReq } from 'src/app/components/interfaces/requests/Exposed/exposedReadSoluyfirlitReq';
import { ExposedSaekiSkjalReq } from 'src/app/components/interfaces/requests/Exposed/exposedSaekiSkjalReq';
import { ExposedSaekiVerdmatHeadReq } from 'src/app/components/interfaces/requests/Exposed/exposedSaekiVerdmatHeadReq';
import { ExposedSaekjaMyndSkraningReq } from 'src/app/components/interfaces/requests/Exposed/exposedSaekjaMyndSkraningReq';
import { ExposedSaekjaMyndirSkraningReq } from 'src/app/components/interfaces/requests/Exposed/exposedSaekjaMyndirSkraningReq';
import { ExposedSamningurSeljendurReq } from 'src/app/components/interfaces/requests/Exposed/exposedSamningurSeljendurReq';
import { ExposedSendaVerdmatReq } from 'src/app/components/interfaces/requests/Exposed/exposedSendaVerdmatReq';
import { ExposedSetOpidHusReq } from 'src/app/components/interfaces/requests/Exposed/exposedSetOpidHusReq';
import { ExposedSoluyfirlitHeadReq } from 'src/app/components/interfaces/requests/Exposed/exposedSoluyfirlitHeadReq';
import { ExposedStarfsmadurBreytaSkraninguReq } from 'src/app/components/interfaces/requests/Exposed/exposedStarfsmadurBreytaSkraninguReq';
import { ExposedThjodskraGetSkjalReq } from 'src/app/components/interfaces/requests/Exposed/exposedThjodskraGetSkjalReq';
import { ExposedThjodskraLeitaFasteignirReq } from 'src/app/components/interfaces/requests/Exposed/exposedThjodskraLeitaFasteignirReq';
import { ExposedUpdateSoluyfirlitReq } from 'src/app/components/interfaces/requests/Exposed/exposedUpdateSoluyfirlitReq';
import { ExposedVeljaAnnanFasteignasalaReq } from 'src/app/components/interfaces/requests/Exposed/exposedVeljaAnnanFasteignasalaReq';
import { ExposedVisirEydaSkraninguReq } from 'src/app/components/interfaces/requests/Exposed/exposedVisirEydaSkraninguReq';
import { ExposedVisirStofnaSkraninguReq } from 'src/app/components/interfaces/requests/Exposed/exposedVisirStofnaSkraninguReq';
import { ExposedVistaFasteignavottordPdfReq } from 'src/app/components/interfaces/requests/Exposed/exposedVistaFasteignavottordPdfReq';
import { ExposedVistaFasteignayfirlitPdfReq } from 'src/app/components/interfaces/requests/Exposed/exposedVistaFasteignayfirlitPdfReq';
import { ExposedVistaVedbandayfirlitPdfReq } from 'src/app/components/interfaces/requests/Exposed/exposedVistaVedbandayfirlitPdfReq';
import { ListFasteignDistancesReq } from 'src/app/components/interfaces/requests/List/listFasteignDistancesReq';
import { ExposedCheckTilSoluRes } from 'src/app/components/interfaces/responses/Exposed/exposedCheckTilSoluRes';
import { ExposedFasteignYfirlitRes } from 'src/app/components/interfaces/responses/Exposed/exposedFasteignYfirlitRes';
import { ExposedGeneratePdfThjonustusamningurKaupendurRes } from 'src/app/components/interfaces/responses/Exposed/exposedGeneratePdfThjonustusamningurKaupendurRes';
import { ExposedGeneratePdfThjonustusamningurSeljendurRes } from 'src/app/components/interfaces/responses/Exposed/exposedGeneratePdfThjonustusamningurSeljendurRes';
import { ExposedGenerateSoluyfirlitPdfReq } from 'src/app/components/interfaces/responses/Exposed/exposedGenerateSoluyfirlitPdfReq';
import { ExposedGetFasteignStateAndAccessRes } from 'src/app/components/interfaces/responses/Exposed/exposedGetFasteignStateAndAccessRes';
import { ExposedGetLastSoluyfirlitRes } from 'src/app/components/interfaces/responses/Exposed/exposedGetLastSoluyfirlitRes';
import { ExposedLogicKannaSkraningTilRes } from 'src/app/components/interfaces/responses/Exposed/exposedLogicKannaSkraningTilRes';
import { ExposedThjodskraGetFasteignRes } from 'src/app/components/interfaces/responses/Exposed/exposedThjodskraGetFasteignRes';
import { ExposedThjodskraGetSkjalRes } from 'src/app/components/interfaces/responses/Exposed/exposedThjodskraGetSkjalRes';
import { ExposedThjodskraLeitaFasteignRes } from 'src/app/components/interfaces/responses/Exposed/exposedThjodskraLeitaFasteignRes';
import { ExposedVistaFasteignavottordPdfRes } from 'src/app/components/interfaces/responses/Exposed/exposedVistaFasteignavottordPdfRes';
import { ExposedVistaFasteignayfirlitPdfRes } from 'src/app/components/interfaces/responses/Exposed/exposedVistaFasteignayfirlitPdfRes';
import { ExposedVistaVedbandayfirlitPdfRes } from 'src/app/components/interfaces/responses/Exposed/exposedVistaVedbandayfirlitPdfRes';
import { ListBaejarfelagRes } from 'src/app/components/interfaces/responses/List/listBaejarfelagRes';
import { ListFasteignDistancesRes } from 'src/app/components/interfaces/responses/List/listFasteignDistancesRes';
import { ListFasteignHlutdeildarlanRes } from 'src/app/components/interfaces/responses/List/listFasteignHlutdeildarlanRes';
import { ListFasteignirSkjolRes } from 'src/app/components/interfaces/responses/List/listFasteignirSkjolRes';
import { ListMinarFasteignaSkraningarRes } from 'src/app/components/interfaces/responses/List/listMinarFasteignaSkraningarRes';
import { ListPostnumerRes } from 'src/app/components/interfaces/responses/List/listPostnumerRes';
import { ListSvaediRes } from 'src/app/components/interfaces/responses/List/listSvaediRes';
import { ListTegundirHusnaedisRes } from 'src/app/components/interfaces/responses/List/listTegundirHusnaedisRes';
import { ListTilSoluEignirRes } from 'src/app/components/interfaces/responses/List/listTilSoluEignirRes';
import { ListWorkflowStatusesRes } from 'src/app/components/interfaces/responses/List/listWorkflowStatusesRes';
import { ListWorkflowStatusesSimplifiedRes } from 'src/app/components/interfaces/responses/List/listWorkflowStatusesSimplifiedRes';
import { ReadFasteignMetaDataRes } from 'src/app/components/interfaces/responses/Read/readFasteignMetaDataRes';
import { ReadFasteignSkraningaryfirlitRes } from 'src/app/components/interfaces/responses/Read/readFasteignSkraningaryfirlitRes';
import { ReadFasteignVisitsRes } from 'src/app/components/interfaces/responses/Read/readFasteignVisitsRes';
import { ReadSkjalRes } from 'src/app/components/interfaces/responses/Read/readSkjalRes';
import { ReadSoluyfirlitHeadRes } from 'src/app/components/interfaces/responses/Read/readSoluyfirlitHeadRes';
import { ReadSoluyfirlitRes } from 'src/app/components/interfaces/responses/Read/readSoluyfirlitRes';
import { ReadVerdmatRes } from 'src/app/components/interfaces/responses/Read/readVerdmatRes';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../../system/alerts/alerts.service';
import { LoaderService } from '../../system/loader/loader.service';
import { NotandiService } from '../notandi/notandi.service';

@Injectable({
  providedIn: 'root'
})
export class FasteignService {

  private httpRequestHelper = new HttpRequestHelper();

  // ------------------------------------------------------------------------------
  /**
   * Segir til um hvaða fasteign var valin þegar leita var eftir fasteign við skráningu.
   */
  private bhleitaFasteignValin = new BehaviorSubject<ExposedThjodskraLeitaFasteignRes>(null);

  leitaFasteignValin = this.bhleitaFasteignValin.asObservable();

  changeLeitaFasteignValin(fasteign: ExposedThjodskraLeitaFasteignRes) {
    this.bhleitaFasteignValin.next(fasteign);
  }
  // ------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------
  /**
   * Heldur utan um valið skjal
   */
  private bhvalidSkjal = new BehaviorSubject<ExposedThjodskraGetSkjalRes>(null);

  validSkjal = this.bhvalidSkjal.asObservable();

  changeValidSkjalInit(skjal: ExposedThjodskraGetSkjalRes) {
    this.bhvalidSkjal.next(skjal);
  }

  // ------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------
  private bhfrumgognFasteignValin = new BehaviorSubject<ExposedThjodskraGetFasteignRes>(null);

  frumgognFasteignValin = this.bhfrumgognFasteignValin.asObservable();

  changeFrumgognFasteignValin(fasteign: ExposedThjodskraGetFasteignRes) {
    this.bhfrumgognFasteignValin.next(fasteign);
  }
  // ------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------
  /**
   * Heldur utan um myndir skráningar
   */

  onRemoveMyndirFasteignar(mynd: ImageItem, previousMyndir: ImageItem[]): Observable<Array<ImageItem>> {
    let subject = new Subject<Array<ImageItem>>();
    // Hendi út upprunalegu.
    const index = previousMyndir.indexOf(mynd, 0);
    let myndMarkedForDelete = mynd;
    myndMarkedForDelete.markedForDelete = true;
    if (index > -1) {
      previousMyndir.splice(index, 1, myndMarkedForDelete);
    }
    subject.next(previousMyndir);
    return subject.asObservable();
  }

  onSetProfilePicture(mynd: ImageItem, previousMyndir: ImageItem[]): Observable<Array<ImageItem>> {
    let subject = new Subject<Array<ImageItem>>();
    // Hendi út upprunalegu.
    const index = previousMyndir.indexOf(mynd, 0);
    let myndMarkedForProfile = mynd;
    myndMarkedForProfile.isProfilePicture = true;
    if (index > -1) {
      previousMyndir.splice(index, 1);
    }
    for (let i = 0; i < previousMyndir.length; i++) {
      previousMyndir[i].isProfilePicture = false;
    }
    previousMyndir.splice(index, 0, myndMarkedForProfile);
    subject.next(previousMyndir);
    return subject.asObservable();
  }

  addMyndirFasteignar(mynd: ImageItem, previousMyndir: ImageItem[]): Observable<Array<ImageItem>> {
    let subject = new Subject<Array<ImageItem>>();
    previousMyndir.push(mynd);
    subject.next(previousMyndir);
    return subject.asObservable();
  }

  // ------------------------------------------------------------------------------
  /**
   * Upplýsingar um fasteignina sem verið er að búa til.
   */
  private bhSkraFasteignCreate = new BehaviorSubject(null);

  skraFasteignCreate = this.bhSkraFasteignCreate.asObservable();
  // ------------------------------------------------------------------------------

  /**
   * Uppfærir stöðuna á skraFasteignCreate.
   * @param fasteign Upplýsingar um þá fasteign sem um ræðir.
   */
  changeSkraFasteignCreate(fasteign: SkraFasteignCreate) {
    this.bhSkraFasteignCreate.next(fasteign);
  }

  constructor(private signatureHelper: SignatureHelper, public http: HttpClient, private loaderService: LoaderService, private alertsService: AlertsService, private router: Router, private notandiService: NotandiService) {

  }

  public leitaFasteignirOnType(req: ExposedThjodskraLeitaFasteignirReq): Observable<Array<ExposedThjodskraLeitaFasteignRes>> {
    let subject = new Subject<Array<ExposedThjodskraLeitaFasteignRes>>();

    this.http.post(environment.localApi + 'FasteignSkraFasteign/leitaFasteignir', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        console.log(error);
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        }
        // Skilum ekki ítarlegri villu því SOAP þjónusta á það til að skila Headers villu
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public leitaFasteignir(req: ExposedThjodskraLeitaFasteignirReq, showLoader = true): Observable<Array<ExposedThjodskraLeitaFasteignRes>> {
    let subject = new Subject<Array<ExposedThjodskraLeitaFasteignRes>>();
    if (showLoader) {
      this.loaderService.changeActiveLoaders(1);
      this.loaderService.changeLoaderMessage('Sæki fasteignir');
    }

    this.http.post(environment.localApi + 'FasteignSkraFasteign/leitaFasteignir', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        console.log(error);
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        }
        // Skilum ekki ítarlegri villu því SOAP þjónusta á það til að skila Headers villu
      }
    ).add(() => {
      if (showLoader) {
        this.loaderService.changeActiveLoaders(-1);
      }
    });
    return subject.asObservable();
  }

  public LeitaFasteignirGetLocations(req: Array<ExposedThjodskraLeitaFasteignRes>): Observable<Array<ExposedThjodskraLeitaFasteignRes>> {
    let subject = new Subject<Array<ExposedThjodskraLeitaFasteignRes>>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Undirbý yfirlitslista');

    this.http.post(environment.localApi + 'FasteignSkraFasteign/leitaFasteignirGetLocations', req).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        console.log(error);
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        } else if (error.status == 401) {
          this.notandiService.changeLoggedInLogout(false);
        } else {
          // Viljum ignora þennan error þegar valið er með lyklaborði.
          if (error.error.errorItem.message.indexOf('StartIndex cannot be less than zero') == -1) {
            this.alertsService.changealertHeaderMessageFooter(error.error.errorItem.friendlyMessage, error.error.errorItem.message, error.error.errorItem.innerMessage);
          }
        }
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public veljaAnnanFasteignasala(req: ExposedVeljaAnnanFasteignasalaReq): Observable<null> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Set nýjan ábyrgðaraðila sölu');
    let subject = new Subject<null>();

    this.http.post(environment.localApi + 'FasteignSkraFasteign/veljaAnnanFasteignasala', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Ábyrgðaraðili sölu hefur verið uppfærður");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(res.returnItem);
      },
      (error) => {
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        } else {
          this.alertsService.changealertHeaderMessageFooter(error.error.errorItem.friendlyMessage, error.error.errorItem.message, error.error.errorItem.innerMessage);
        }
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  /**
   * Skráir nýja fasteign
   * @param req
   * @param initCreate
   * @returns Skilar 0 ef villa. Annars ID á skráningu.
   */
  public initCreate(req: ExposedFasteignInitCreateReq, initCreate: string = "InitCreate"): Observable<number> {
    let subject = new Subject<number>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki frumgögn eignar. Vinsamlegast lokið ekki þessum glugga');
    const self = this;
    setTimeout(
      function () {
        self.loaderService.changeLoaderMessage('Þessi aðgerð getur tekið tíma. Vinsamlegast sýnið biðlund');
      }, 9000);
    this.http.post(environment.localApi + 'FasteignSkraFasteign/' + initCreate, req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        let _reqDis: ExposedGetDistancesReq = {
          FasteignSkraningId: res.returnItem
        }
        this.alertsService.changeAlertSuccessMessage("Skráning #" + res.returnItem + " var stofnuð");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
        subject.next(0);
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  // Skilar því hvort það hafi tekist að sækja skjal.
  public saekiThinglystSkjal(req: ExposedThjodskraGetSkjalReq): Observable<ExposedThjodskraGetSkjalRes> {
    let subject = new Subject<ExposedThjodskraGetSkjalRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki skjal');

    this.http.post(environment.localApi + 'FasteignThjodskra/saekiThinglystSkjal', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public getFasteignSkraning(req: ExposedGetFasteignSkraningReq, loaderMessage: string = 'Sæki skráningaryfirlit'): Observable<ExposedThjodskraGetFasteignRes> {
    let subject = new Subject<ExposedThjodskraGetFasteignRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loaderMessage);

    this.http.post(environment.localApi + 'FasteignSkraFasteign/get', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public getFasteignStateAndAccess(fasteignSkraningId: number, loaderMessage: string = ""): Observable<ExposedGetFasteignStateAndAccessRes> {
    let subject = new Subject<ExposedGetFasteignStateAndAccessRes>();
    this.loaderService.changeActiveLoaders(1);
    if (loaderMessage) {
      this.loaderService.changeLoaderMessage(loaderMessage);
    }

    this.http.get(environment.localApi + 'Fasteign/getFasteignStateAndAccess/' + fasteignSkraningId, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public vistaFasteignayfirlitPdf(req: ExposedVistaFasteignayfirlitPdfReq, loaderMessage: string): Observable<ExposedVistaFasteignayfirlitPdfRes> {
    let subject = new Subject<ExposedVistaFasteignayfirlitPdfRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loaderMessage);

    this.http.post(environment.localApi + 'FasteignSkraFasteign/vistaFasteignayfirlitPdf', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public vistaFasteignavottordPdf(req: ExposedVistaFasteignavottordPdfReq, loaderMessage: string): Observable<ExposedVistaFasteignavottordPdfRes> {
    let subject = new Subject<ExposedVistaFasteignavottordPdfRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loaderMessage);

    this.http.post(environment.localApi + 'FasteignSkraFasteign/vistaFasteignavottordPdf', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public vistaVedbandayfirlitPdf(req: ExposedVistaVedbandayfirlitPdfReq, loaderMessage: string): Observable<ExposedVistaVedbandayfirlitPdfRes> {
    let subject = new Subject<ExposedVistaVedbandayfirlitPdfRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loaderMessage);

    this.http.post(environment.localApi + 'FasteignSkraFasteign/vistaVedbandayfirlitPdf', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }



  public getVisits(req: ExposedGetVisitsReq): Observable<ReadFasteignVisitsRes> {
    let subject = new Subject<ReadFasteignVisitsRes>();
    this.http.get(environment.localApi + 'FasteignTilSolu/getVisits/' + req.FasteignSkraningId).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public getSoluyfirlitHead(req: ExposedSoluyfirlitHeadReq): Observable<ReadSoluyfirlitHeadRes> {
    let subject = new Subject<ReadSoluyfirlitHeadRes>();
    this.http.post(environment.localApi + 'FasteignSoluyfirlit/soluyfirlitHead', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public minarFasteignaSkraningar(req: ExposedListMinarFasteignaSkraningarReq): Observable<Array<ListMinarFasteignaSkraningarRes>> {
    let subject = new Subject<Array<ListMinarFasteignaSkraningarRes>>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage("Sæki fasteignir");

    this.http.post(environment.localApi + 'FasteignMinarFasteignir/list/getMinar', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public minarFasteignirFlytimerktar(req: ExposedListMinarFasteignaSkraningarReq): Observable<Array<any>> {
    let subject = new Subject<Array<ListMinarFasteignaSkraningarRes>>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage("Sæki fasteignir");

    this.http.post(environment.localApi + 'FasteignMinarFasteignir/list/getFlytimerktar', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        console.log(error);
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public listaFylgiskjol(req: ExposedFasteignirSkjolReq): Observable<Array<ListFasteignirSkjolRes>> {
    let subject = new Subject<Array<ListFasteignirSkjolRes>>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki skráningargögn fasteignar');

    this.http.post(environment.localApi + 'FasteignSkraFasteign/listafylgiskjol', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public saekiSkjal(req: ExposedSaekiSkjalReq, loaderMessage = "Sæki skjal"): Observable<ListFasteignirSkjolRes> {
    let subject = new Subject<ListFasteignirSkjolRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loaderMessage);

    this.http.post(environment.localApi + 'FasteignSkraFasteign/saekiSkjal', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        if (res.returnItem == null) {
          this.alertsService.changeAlertErrorMessage("Verðmat finnst ekki");
          this.alertsService.changeAlertErrorShow(true);
        }
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public saekjaMyndirSkraning(req: ExposedSaekjaMyndirSkraningReq, loaderMessage, showLoader = true): Observable<Array<ImageItem>> {
    let subject = new Subject<Array<ImageItem>>();
    if (showLoader) {
      this.loaderService.changeActiveLoaders(1);
      this.loaderService.changeLoaderMessage(loaderMessage);
    }
    this.http.post(environment.localApi + 'FasteignSkraFasteign/saekjaMyndirSkraning', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        for (let i = 0; i < res.returnItem.myndir.length; i++) {
          res.returnItem.myndir[i].imgBase64 = "data:image/jpg;base64," + res.returnItem.myndir[i].imgBase64;
          res.returnItem.myndir[i].markedForDelete = false,
            res.returnItem.myndir[i].submitted = true
        }
        subject.next(res.returnItem.myndir);
      },
      error => {

      }
    ).add(() => {
      if (showLoader) {
        this.loaderService.changeActiveLoaders(-1);
      }
    });
    return subject.asObservable();
  }

  public saekjaMyndSkraning(req: ExposedSaekjaMyndSkraningReq, loaderMessage = "Hleð mynd", showLoader = true): Observable<ImageItem> {
    let subject = new Subject<ImageItem>();
    if (showLoader) {
      this.loaderService.changeActiveLoaders(1);
      this.loaderService.changeLoaderMessage(loaderMessage);
    }
    this.http.post(environment.localApi + 'FasteignSkraFasteign/saekjaMyndSkraning', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        res.returnItem.imgBase64 = "data:image/jpg;base64," + res.returnItem.imgBase64;
        res.returnItem.markedForDelete = false,
          res.returnItem.submitted = true
        subject.next(res.returnItem);
      },
      error => {

      }
    ).add(() => {
      if (showLoader) {
        this.loaderService.changeActiveLoaders(-1);
      }
    });
    return subject.asObservable();
  }

  public vistaSkraninguFasteignar(req: ExposedVistaSkraninguFasteignarReq, showSuccessMessage: boolean, loaderMessage: string): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Vista skráningu');
    this.http.post(environment.localApi + 'FasteignSkraFasteign/vistaSkraninguFasteignar', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        if (showSuccessMessage) {
          this.alertsService.changeAlertSuccessMessage("Skráning vistuð");
          this.alertsService.changeAlertSuccessShow(true);
        }
        subject.next(true);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);

    });
    return subject.asObservable();
  }

  public starfsmadurBreytaSkraningu(req: ExposedStarfsmadurBreytaSkraninguReq) {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Vista skráningu');
    return this.http.post(environment.localApi + 'FasteignSkraFasteign/starfsmadurBreytaSkraningu', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertSuccessMessage("Skráning vistuð");
        this.alertsService.changeAlertSuccessShow(true);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
  }

  public kannaVoktunSeld(fasteignSkraningId: number): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki gögn');
    this.http.get(environment.localApi + 'FasteignSkraFasteign/kannaVoktunSeld/' + fasteignSkraningId.toString(), this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public samningurSeljendur(req: ExposedSamningurSeljendurReq): Observable<ReadSkjalRes> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki samning');
    let subject = new Subject<ReadSkjalRes>();
    this.http.post(environment.localApi + 'FasteignSkraFasteign/samningurSeljendur', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public saekiVerdmatHead(req: ExposedSaekiVerdmatHeadReq, loaderMessage = "Sæki upplýsingar verðmats"): Observable<ReadVerdmatRes> {
    let subject = new Subject<ReadVerdmatRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loaderMessage);

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/verdmat/saekjaHead', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public fasteignYfirlit(fasteignSkraningId, loaderMessage: string): Observable<ExposedFasteignYfirlitRes> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loaderMessage);

    return this.http.get<{ returnItem: ExposedFasteignYfirlitRes }>(environment.localApi + 'FasteignTilSolu/fasteignYfirlit/read/' + fasteignSkraningId, this.httpRequestHelper.getRequestOptionsAuth())
      .pipe(
        map(response => response.returnItem), // Ná í returnItem úr svargögnunum
        catchError(error => {
          return throwError(() => new Error('Error fetching property details')); // Propagate the error
        }),
        finalize(() => {
          this.loaderService.changeActiveLoaders(-1); // This will always execute after subscription completes or errors out
        })
      );
  }

  public metadata(fasteignSkraningId: number): Observable<ReadFasteignMetaDataRes> {
    let subject = new Subject<ReadFasteignMetaDataRes>();
    this.http.get(environment.localApi + 'FasteignTilSolu/' + fasteignSkraningId + '/metaData').subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
        subject.next(null);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public listTilSoluFasteignir(req: ExposedListTilSoluEignirReq): Observable<Array<ListTilSoluEignirRes>> {
    let subject = new Subject<Array<ListTilSoluEignirRes>>();
    this.http.post(environment.localApi + 'FasteignTilSolu/fasteignir/list', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem.data);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
        subject.next([]);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public faTilkynningu(fasteignSkraningId: number): Observable<null> {
    let subject = new Subject<null>();
    this.http.post(environment.localApi + 'FasteignTilSolu/vaentanlegASolu/faTilkynningu/' + fasteignSkraningId, null, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Þú færð tilkynningu um leið og fasteignin fer á sölu");
        this.alertsService.changeAlertSuccessShow(true);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public checkFaTilkynningu(fasteignSkraningId: number): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.http.post(environment.localApi + 'FasteignTilSolu/vaentanlegASolu/checkFaTilkynningu/' + fasteignSkraningId, null, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public fasteignDistances(req: ListFasteignDistancesReq): Observable<Array<ListFasteignDistancesRes>> {
    this.loaderService.changeActiveLoaders(1);

    return this.http.post<{ returnItem: Array<ListFasteignDistancesRes> }>(
      environment.localApi + 'FasteignTilSolu/fasteignYfirlit/distances',
      req,
      this.httpRequestHelper.getRequestOptionsAuth()
    ).pipe(
      map(response => response.returnItem), // Ná í returnItem úr svargögnunum
      catchError(error => {
        HttpError(this.alertsService, this.router, error, "", "", "", ""); // Handle the error
        return of([]); // Return an empty array in case of error
      }),
      finalize(() => {
        this.loaderService.changeActiveLoaders(-1); // This will always execute after subscription completes or errors out
      })
    );
  }

  public hlutdeildarSubmitVaktanir(req: ExposedHlutdeildarlanSubmitVaktanirReq, hasVaktari: boolean): Observable<null> {
    let subject = new Subject<null>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(hasVaktari ? "Uppfæri skráningu á póstlista" : "Skrái á póstlista");
    this.http.post(environment.localApi + 'FasteignHlutdeildarlan/SubmitVaktanir', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(null);
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage(hasVaktari ? "Skráning á póstlista uppfærð" : "Skráning á póstlista tókst");
        this.alertsService.changeAlertSuccessShow(true);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public hlutdeildarlanGetVaktanir(): Observable<Array<ListFasteignHlutdeildarlanRes>> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage("Sæki þínar upplýsingar");
    let subject = new Subject<Array<ListFasteignHlutdeildarlanRes>>();
    this.http.get(environment.localApi + 'FasteignHlutdeildarlan/GetVaktanir', this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public submitVisit(req: ExposedSubmitVisitReq) {
    if (getCookie("sessionId") == "") {
      setCookie("sessionId", makeid(10), 1);
    }

    this.http.get('https://api.ipify.org/?format=json').subscribe(
      (res: any) => {
        // req.IpAddress = res.
        req.IpAddress = res.ip + "||" + getCookie("sessionId");
        this.http.post(environment.localApi + 'FasteignTilSolu/submitVisit', req).subscribe(
          () => {
          },
          error => {
            console.log(error);
          }
        ).add(() => {
        });
      },
      error => {
        console.log(error);
      }
    ).add(() => {
    });
  }

  public listFasteignMyndir(req: ExposedListFasteignMyndirReq): Observable<Array<string>> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage("Hleð myndum");
    let subject = new Subject<Array<string>>();
    this.http.post(environment.localApi + 'FasteignTilSolu/fasteignMyndir/list', req).subscribe(
      (res: any) => {
        subject.next(res.returnItem.data);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public frittVerdmat(req: ExposedFrittVerdmatReq): Observable<null> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sendi beiðni um verðmat');
    let subject = new Subject<null>();

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/frittVerdmat', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Beiðni send");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(null);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public birtaTilSoluEFasteignir(fasteignSkraningId: number, birta: boolean): Observable<null> {
    this.loaderService.changeActiveLoaders(1);
    if (birta) {
      this.loaderService.changeLoaderMessage('Skrái fasteign á auglýsingasvæði e-fasteigna');
    } else {
      this.loaderService.changeLoaderMessage('Fjarlægi fasteign af auglýsingasvæði e-fasteigna');
    }
    let subject = new Subject<null>();
    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/birtaTilSoluEFasteignir/' + fasteignSkraningId + "/" + birta, null, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertShowIcon(true);
        if (birta) {
          this.alertsService.changeAlertSuccessMessage("Fasteign hefur verið skráð á auglýsingasvæði e-fasteigna");
        } else {
          this.alertsService.changeAlertSuccessMessage("Fasteign hefur verið fjarlægð af auglýsingasvæði e-fasteigna");
        }
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(null);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public mblStofnaSkraningu(req: ExposedMblStofnaSkraninguReq): Observable<null> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sendi á vef MBL');
    let subject = new Subject<null>();

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/mbl/stofnaSkraningu', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Fasteign hefur verið skráð á vefsvæði MBL");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(null);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public mblEydaSkraningu(req: ExposedMblEydaSkraninguReq): Observable<null> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Eyði fasteign af vefsvæði MBL');
    let subject = new Subject<null>();

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/mbl/eydaSkraningu', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Fasteign hefur verið eytt af vefsvæði MBL");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(null);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public visirEydaSkraningu(req: ExposedVisirEydaSkraninguReq): Observable<null> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Eyði fasteign af vefsvæði Vísis');
    let subject = new Subject<null>();

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/visir/eydaSkraningu', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Fasteign hefur verið eytt af vefsvæði Vísis");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(null);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public visirStofnaSkraningu(req: ExposedVisirStofnaSkraninguReq): Observable<null> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sendi á vef Vísis');
    let subject = new Subject<null>();

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/visir/stofnaSkraningu', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Fasteign hefur verið skráð á vefsvæði Vísis");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(null);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public skraningarYfirlit(req: ExposedGetSkraningaryfirlitReq): Observable<ReadFasteignSkraningaryfirlitRes> {
    let subject = new Subject<ReadFasteignSkraningaryfirlitRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki skráningaryfirlit');
    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/getYfirlit', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        console.log(error);
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        } else if (error.status == 401) {
          this.notandiService.changeLoggedInLogout(false);
        } else {
          try {
            if (error.error.returnItem.stadaBokunarCode >= 5) {
              this.router.navigateByUrl('/forsida/minar-fasteignir/skraningaryfirlit/' + req.FasteignSkraningId + '/breyta');
            } else {
              this.alertsService.changealertHeaderMessageFooter(error.error.errorItem.friendlyMessage, error.error.errorItem.message, error.error.errorItem.innerMessage);
            };
          } catch (e) {
            this.alertsService.changealertHeaderMessageFooter("Óvænt villa kom upp", "Vinsamlegast hafið samband við kerfisstjóra.", "");
          }
        }
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public sendaVerdmat(req: ExposedSendaVerdmatReq): Observable<null> {
    let subject = new Subject<null>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sendi verðmat');
    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/verdmat/senda', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        this.alertsService.changeAlertSuccessMessage("Verðmat sent");
        this.alertsService.changeAlertSuccessShow(true);
      },
      error => {
        console.log(error);
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        }
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public breytaVerdmat(req: ExposedBreytaVerdmatReq): Observable<null> {
    let subject = new Subject<null>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sendi breytingar á verðmati');
    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/verdmat/breyta', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        this.alertsService.changeAlertSuccessMessage("Verðmati hefur verið breytt");
        this.alertsService.changeAlertSuccessShow(true);
      },
      error => {
        console.log(error);
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        }
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public vaentanlegASolu(fasteignSkraningId: number, vaentanlegASolu: boolean): Observable<null> {
    let subject = new Subject<null>();
    this.loaderService.changeActiveLoaders(1);
    if (vaentanlegASolu) {
      this.loaderService.changeLoaderMessage('Skrái fasteign væntanlega á sölu');
    } else {
      this.loaderService.changeLoaderMessage('Fjarlægi merkingu "Væntanleg á sölu"');
    }

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/vaentanlegASolu/' + fasteignSkraningId, null, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        if (vaentanlegASolu) {
          this.alertsService.changeAlertSuccessMessage("Fasteign skráð sem væntanleg á sölu");
        } else {
          this.alertsService.changeAlertSuccessMessage("Aðgerð tókst");
        }

        this.alertsService.changeAlertSuccessShow(true);
      },
      error => {
        console.log(error);
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        }
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public setOpidHus(req: ExposedSetOpidHusReq): Observable<null> {
    let subject = new Subject<null>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Vista opið hús');

    req.OpidHus = dateToGMT(req.OpidHus);
    req.OpidHusTil = dateToGMT(req.OpidHusTil);

    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/opidHus/stofna', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Opið hús skráð");
        this.alertsService.changeAlertSuccessShow(true);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public haettaVidOpidHus(req: ExposedHaettaVidOpidHusReq, showSuccess: boolean = true): Observable<null> {
    let subject = new Subject<null>();
    // Hratt fall, þarf ekki loading
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Eyði opnu húsi');
    this.http.post(environment.localApi + 'FasteignSkraningaryfirlit/opidHus/eyda', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        if (showSuccess) {
          this.alertsService.changeAlertShowIcon(true);
          this.alertsService.changeAlertSuccessMessage("Skráningu opins húss eytt");
          this.alertsService.changeAlertSuccessShow(true);
        }
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public updateSoluyfirlit(req: ExposedUpdateSoluyfirlitReq, showSuccessMessage: boolean = true, showMainLoader = true): Observable<null> {
    if (!showMainLoader) {
      this.loaderService.changeLoaderBlocked(true);
    }
    let subject = new Subject<null>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Vista upplýsingar söluyfirlits');

    req.fyrstiAfhendingardagur = dateToGMT(req.fyrstiAfhendingardagur);
    req.skodunardagur = dateToGMT(req.skodunardagur);

    this.http.post(environment.localApi + 'FasteignSoluyfirlit/update', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      res => {
        subject.next(null);
        if (showSuccessMessage) {
          this.alertsService.changeAlertShowIcon(true);
          this.alertsService.changeAlertSuccessMessage("Upplýsingar söluyfirlits vistaðar");
          this.alertsService.changeAlertSuccessShow(true);
        }
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
      if (!showMainLoader) {
        this.loaderService.changeLoaderBlocked(false);
      }
    });
    return subject.asObservable();
  }

  public generateSoluyfirlitPdf(req: ExposedGenerateSoluyfirlitPdfReq, hideMainLoader = false): Observable<null> {
    if (hideMainLoader) {
      this.loaderService.changeLoaderBlocked(true);
    }
    let subject = new Subject<null>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Undirbý söluyfirlit');
    this.http.post(environment.localApi + 'FasteignSoluyfirlit/skraFasteign/generatePdf', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      res => {
        subject.next(null);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
      if (hideMainLoader) {
        this.loaderService.changeLoaderBlocked(false);
      }
    });
    return subject.asObservable();
  }

  public generateSoluthoknunSeljendurPdf(req: ExposedGeneratePdfThjonustusamningurSeljendurReq, loadingText = "Undirbý samning"): Observable<ExposedGeneratePdfThjonustusamningurSeljendurRes> {
    let subject = new Subject<ExposedGeneratePdfThjonustusamningurSeljendurRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loadingText);
    this.http.post(environment.localApi + 'FasteignSoluthoknun/seljendur/GeneratePdf', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public generateSoluthoknunKaupendurPdf(req: ExposedGeneratePdfThjonustusamningurKaupendurReq, loadingText = "Undirbý samning"): Observable<ExposedGeneratePdfThjonustusamningurKaupendurRes> {
    let subject = new Subject<ExposedGeneratePdfThjonustusamningurKaupendurRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage(loadingText);
    this.http.post(environment.localApi + 'FasteignSoluthoknun/kaupendur/GeneratePdf', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public kannaSkraningTil(req: ExposedLogicKannaSkraningTilReq): Observable<ExposedLogicKannaSkraningTilRes> {
    let subject = new Subject<ExposedLogicKannaSkraningTilRes>();
    this.loaderService.changeActiveLoaders(1);
    this.http.post(environment.localApi + 'FasteignSkraFasteign/kannaSkraningTil', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        // Þurfum ekki að skila villu ef notandi er ekki innskráður þar sem þetta
        // kall er nice-to-have.
        subject.next({ tilSkraning: false });
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public getLastSoluyfirlit(req: ExposedGetLastSoluyfirlitReq): Observable<ExposedGetLastSoluyfirlitRes> {
    let subject = new Subject<ExposedGetLastSoluyfirlitRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki söluyfirlit');
    this.http.post(environment.localApi + 'FasteignSoluyfirlit/getSoluyfirlitPdf', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public readSoluyfirlit(req: ExposedReadSoluyfirlitReq, showMainLoader = true): Observable<ReadSoluyfirlitRes> {
    if (!showMainLoader) {
      this.loaderService.changeLoaderBlocked(true);
    }
    let subject = new Subject<ReadSoluyfirlitRes>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki upplýsingar söluyfirlits');
    this.http.post(environment.localApi + 'FasteignSoluyfirlit/read', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
      if (!showMainLoader) {
        this.loaderService.changeLoaderBlocked(false);
      }
    });
    return subject.asObservable();
  }

  public listWorkflowStatusesSimplified(): Observable<Array<ListWorkflowStatusesSimplifiedRes>> {
    this.loaderService.changeActiveLoaders(1);
    let subject = new Subject<Array<ListWorkflowStatusesSimplifiedRes>>();
    this.http.get(environment.localApi + 'FasteignFilter/listWorkflowStatusesSimplified', this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem.data);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public unMarkFavorite(req: ExposedDeleteFasteignirFavoritesReq) {
    let subject = new Subject<boolean>();
    this.http.post(environment.localApi + 'FasteignMinarFasteignir/' + req.FasteignSkraningId + '/unMarkFavorite', null, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(true);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
        subject.next(false);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public markFavorite(req: ExposedFasteignirFavoritesReq): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.http.post(environment.localApi + 'FasteignMinarFasteignir/' + req.FasteignSkraningId + '/markFavorite', null, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(true);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
        subject.next(false);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public checkIfMarkedFavorite(req: ExposedCheckIfMarkedFavoriteReq): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.http.get(environment.localApi + 'FasteignMinarFasteignir/' + req.FasteignSkraningId + '/checkIfMarkedFavorite', this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
        subject.next(false);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public listWorkflowStatuses(): Observable<Array<ListWorkflowStatusesRes>> {
    let subject = new Subject<Array<ListWorkflowStatusesRes>>();
    this.http.get(environment.localApi + 'FasteignFilter/listWorkflowStatuses', this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem.data);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public listBaejarfelag(): Observable<Array<ListBaejarfelagRes>> {
    this.loaderService.changeActiveLoaders(1);

    return this.http.get<{ returnItem: { data: Array<ListBaejarfelagRes> } }>(environment.localApi + 'FasteignFilter/listBaejarfelag')
      .pipe(
        map(response => response.returnItem.data),
        catchError(error => {
          HttpError(this.alertsService, this.router, error, "", "", "", "");
          return of([]);
        }),
        finalize(() => {
          this.loaderService.changeActiveLoaders(-1);
        })
      );
  }


  public listSvaedi(): Observable<Array<ListSvaediRes>> {
    this.loaderService.changeActiveLoaders(1);

    return this.http.get<{ returnItem: { data: Array<ListSvaediRes> } }>(environment.localApi + 'FasteignFilter/listSvaedi')
      .pipe(
        map(response => response.returnItem.data),
        catchError(error => {
          HttpError(this.alertsService, this.router, error, "", "", "", "");
          return of([]);
        }),
        finalize(() => {
          this.loaderService.changeActiveLoaders(-1);
        })
      );
  }

  public saekjaTegundirHusnaedis(): Observable<Array<ListTegundirHusnaedisRes>> {
    this.loaderService.changeActiveLoaders(1);

    return this.http.get<{ returnItem: { data: Array<ListTegundirHusnaedisRes> } }>(environment.localApi + 'FasteignFilter/listTegundirHusnaedis')
      .pipe(
        map(response => response.returnItem.data),
        catchError(error => {
          HttpError(this.alertsService, this.router, error, "", "", "", "");
          return of([]);
        }),
        finalize(() => {
          this.loaderService.changeActiveLoaders(-1);
        })
      );
  }

  public listPostnumer(): Observable<Array<ListPostnumerRes>> {
    this.loaderService.changeActiveLoaders(1);

    return this.http.get<{ returnItem: { data: Array<ListPostnumerRes> } }>(environment.localApi + 'FasteignFilter/listPostnumer')
      .pipe(
        map(response => response.returnItem.data), // Ná í returnItem.data úr svargögnunum
        catchError(error => {
          HttpError(this.alertsService, this.router, error, "", "", "", ""); // Handle the error
          return of([]); // Return empty array in case of error
        }),
        finalize(() => {
          this.loaderService.changeActiveLoaders(-1); // This will always execute after subscription completes or errors out
        })
      );
  }

  public initUpdate(req: ExposedFasteignInitUpdateReq): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki uppfærð gögn frá Þjóðskrá Íslands');
    const self = this;
    this.http.post(environment.localApi + 'FasteignSkraFasteign/initUpdate', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        this.alertsService.changeAlertSuccessMessage("Gögn sótt og skráning uppfærð");
        this.alertsService.changeAlertSuccessShow(true);
        subject.next(true);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
        subject.next(false);
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public checkTilSolu(fasteignSkraningId: number): Observable<ExposedCheckTilSoluRes> {
    this.loaderService.changeActiveLoaders(1);

    return this.http.get<{ returnItem: ExposedCheckTilSoluRes }>(environment.localApi + 'FasteignTilSolu/checkTilSolu/' + fasteignSkraningId, this.httpRequestHelper.getRequestOptionsAuth())
      .pipe(
        map(response => response.returnItem),
        catchError(error => {
          HttpError(this.alertsService, this.router, error, "", "", "", "");
          return of(null);
        }),
        finalize(() => {
          this.loaderService.changeActiveLoaders(-1);
        })
      );
  }
}
