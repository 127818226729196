// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-link {
  color: white !important;
}

.c-link .fa-angle-right {
  margin-left: 3px;
  transition: 0.3s;
}

.c-link:hover .fa-angle-right {
  margin-left: 7px;
}

#fb-sharer {
  position: relative;
  top: 5px;
}

#fb-sharer span {
  overflow: hidden;
}

#fb-container {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.6);
  height: 74px;
  padding: 5px;
  display: block;
}

.mat-form-field-outline-thick {
  color: white !important;
}

.mat-form-field-label {
  color: white !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}`, "",{"version":3,"sources":["webpack://./src/app/theme/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,oCAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;;EAEE,8CAAA;EACA,wBAAA;EACA,SAAA,EAAA,uEAAA;AACF;;AAEA;EACE,0BAAA,EAAA,YAAA;AACF","sourcesContent":[".c-link {\r\n  color: white !important;\r\n}\r\n\r\n.c-link .fa-angle-right {\r\n  margin-left: 3px;\r\n  transition: 0.3s;\r\n}\r\n\r\n.c-link:hover .fa-angle-right {\r\n  margin-left: 7px;\r\n}\r\n\r\n#fb-sharer {\r\n  position: relative;\r\n  top: 5px;\r\n}\r\n\r\n#fb-sharer span {\r\n  overflow: hidden;\r\n}\r\n\r\n#fb-container {\r\n  margin-top: 10px;\r\n  background: rgb(255, 255, 255, 0.6);\r\n  height: 74px;\r\n  padding: 5px;\r\n  display: block;\r\n}\r\n\r\n.mat-form-field-outline-thick {\r\n  color: white !important;\r\n}\r\n\r\n.mat-form-field-label {\r\n  color: white !important;\r\n}\r\n\r\ninput::-webkit-outer-spin-button,\r\ninput::-webkit-inner-spin-button {\r\n  /* display: none; <- Crashes Chrome on hover */\r\n  -webkit-appearance: none;\r\n  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */\r\n}\r\n\r\ninput[type=\"number\"] {\r\n  -moz-appearance: textfield; /* Firefox */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
