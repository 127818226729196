export function firstAndMiddleNameUser(fullname): string {
    let names = fullname.split(' ');
    let name = "";
    if (names.length > 2) {
        name = names[0] + " " + names[1];
    } else {
        name = names[0];
    }
    return name;
}
