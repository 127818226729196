import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertsService } from '../alerts/alerts.service';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    // -----------------------------------------------------
    // ------------------ ACTIVE LOADERS -------------------
    // Segir til um hversu margir acitve loaders eru í notkun.
    private bhActiveLoaders = new BehaviorSubject(0);

    activeLoaders = this.bhActiveLoaders.asObservable();

    hasActiveDotsInterval = false;
    changeActiveLoaders(adder: number) {
        let dotsInterval;
        let currentActiveLoaders: number;
        this.activeLoaders.subscribe(ca => currentActiveLoaders = ca);
        if ((currentActiveLoaders + adder) >= 0) {
            this.bhActiveLoaders.next(currentActiveLoaders + adder);
        }
        if (currentActiveLoaders == 1) {
            this.changeLoader(true);
            try {
                if (!this.hasActiveDotsInterval) {
                    dotsInterval = setInterval(() => {
                        var wait = document.getElementById("wait");
                        if (wait) {
                            if (wait.innerHTML.length > 2) {
                                wait.innerHTML = "";
                            }
                            else {
                                wait.innerHTML += ".";
                            }
                        }
                    }, 500);
                }
                this.hasActiveDotsInterval = true;
            } catch (e) { }
        } else if (currentActiveLoaders == 0) {
            this.changeLoader(false);
            this.changeLoaderMessage('');
            clearInterval(dotsInterval);
        }
    }

    // -----------------------------------------------------
    // ------------------ LOADER MESSAGE -------------------
    private bhLoaderMessage = new BehaviorSubject('');
    private bhLoaderMessageChanged = new BehaviorSubject(false);
    /**
     * Heldur utan um loader message
     */
    loaderMessage = this.bhLoaderMessage.asObservable();

    /**
     * Breyta loader message.
     * @param message loader message sem á að birta.
     */
    changeLoaderMessage(message: string) {
        this.bhLoaderMessageChanged.next(true);

        // Passar að taka punkt af aftast ef hann er fyrir mistök inni.
        // Viljum ekki hafa punkt aftast í loader texta
        if (message.endsWith(".")) {
            message = message.substring(0, message.length - 1)
        }

        if (message != "") {
            this.bhLoaderMessage.next(message);
        } else {
            this.bhLoaderMessage.next("");
        }
    }


    // -----------------------------------------------------
    // ------------------ MAIN-LOADER ----------------------
    private bhLoader = new BehaviorSubject(false);
    private bhLoaderDelayed = new BehaviorSubject(false);
    private bhLoaderBlocked = new BehaviorSubject(false);
    private bhShowImageLoader = new BehaviorSubject(false);
    /**
     * Segir til um hvort sýna eigi loader.
     */
    loader = this.bhLoader.asObservable();
    loaderDelayed = this.bhLoaderDelayed.asObservable();
    loaderBlocked = this.bhLoaderBlocked.asObservable();
    showImageLoader = this.bhShowImageLoader.asObservable();

    /**
     * Breyta því hvort loader sé sjáanlegur eða ekki.
     * @param show sýna loader
     */
    changeLoader(show: boolean) {
        let currentActiveLoaders: number;
        let isBlocked: boolean;
        this.activeLoaders.subscribe(ca => currentActiveLoaders = ca);
        this.loaderBlocked.subscribe(bl => isBlocked = bl);

        if (!isBlocked) {
            this.bhLoader.next(show);
            const self = this;
            if (!show) {
                setTimeout(function () {
                    self.bhLoaderDelayed.next(show);
                }, 500);
            } else {
                self.bhLoaderDelayed.next(show);
            }
        } else {
            this.bhActiveLoaders.next(0);
            this.bhLoader.next(false);
        }
    }

    /**
     * Alveg sama hvað active loaders er þá mun loader ekki birtast þar til
     * blocked er sett sem false aftur.
     * @param blocked
     */
    changeLoaderBlocked(blocked: boolean) {
        if (blocked) {
            this.bhActiveLoaders.next(0);
            this.bhLoader.next(false);
        }
        this.bhLoaderBlocked.next(blocked);
    }

    /**
     * Segir til um hvort sýna eigi mynd með loader
     * @param show
     */
    changeShowImageLoader(show: boolean) {
        this.bhShowImageLoader.next(show);
    }

    constructor(private alertsService: AlertsService) { }
}
