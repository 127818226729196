import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {

    constructor() { }

    // ------------------------------------------------------------------------------

    private bhAlertSuccessThread = new BehaviorSubject(null);
    private bhAlertInfoThread = new BehaviorSubject(null);
    private bhAlertErrorThread = new BehaviorSubject(null);
    private bhalertHidden = new BehaviorSubject(false);
    private bhalertShow = new BehaviorSubject(false);
    private bhSuccessShow = new BehaviorSubject(false);
    private bhInfoShow = new BehaviorSubject(false);
    private bhAlertSuccessMessage = new BehaviorSubject('');
    private bhAlertInfoHeader = new BehaviorSubject('');
    private bhAlertInfoMessage = new BehaviorSubject('');
    private bhAlertErrorShow = new BehaviorSubject(false);
    private bhAlertShowIcon = new BehaviorSubject(true);
    private bhAlertErrorMessage = new BehaviorSubject('');
    private bhalertHeader = new BehaviorSubject('');
    private bhalertMessage = new BehaviorSubject('');
    private bhalertFooter = new BehaviorSubject('');
    /**
     * Heldur utan um alert message
     */
    alertSuccessThread = this.bhAlertSuccessThread.asObservable();
    alertInfoThread = this.bhAlertInfoThread.asObservable();
    alertErrorThread = this.bhAlertErrorThread.asObservable();
    alertHidden = this.bhalertHidden.asObservable();
    alertShow = this.bhalertShow.asObservable();
    alertShowIcon = this.bhAlertShowIcon.asObservable();
    alertSuccessShow = this.bhSuccessShow.asObservable();
    alertSuccessMessage = this.bhAlertSuccessMessage.asObservable();
    alertInfoShow = this.bhInfoShow.asObservable();
    alertInfoMessage = this.bhAlertInfoMessage.asObservable();
    alertInfoHeader = this.bhAlertInfoHeader.asObservable();
    alertHeader = this.bhalertHeader.asObservable();
    alertMessage = this.bhalertMessage.asObservable();
    alertFooter = this.bhalertFooter.asObservable();
    alertErrorShow = this.bhAlertErrorShow.asObservable();
    alertErrorMessage = this.bhAlertErrorMessage.asObservable();

    clear() {
        this.bhalertHeader.next('');
        this.bhalertMessage.next('');
        this.bhalertFooter.next('');
        this.bhalertHidden.next(false);
    }

    changealertHeaderMessageFooter(header: string, message: string, footer: string) {
        this.bhalertHeader.next(header);
        this.bhalertMessage.next(message);
        this.bhalertFooter.next(footer);
        this.bhalertHidden.next(true);
    }

    changealertHeader(message: string) {
        this.bhalertHeader.next(message);
    }

    changeAlertInfoMessage(message: string) {
        this.bhAlertInfoMessage.next(message);
    }

    changeAlertInfoHeader(message: string) {
        this.bhAlertInfoHeader.next(message);
    }

    changeAlertSuccessMessage(message: string) {
        this.bhAlertSuccessMessage.next(message);
    }

    changeAlertSuccessShow(show: boolean) {
        try {
            var threadTemp;
            this.alertSuccessThread.subscribe(thread => threadTemp = thread);
            clearInterval(threadTemp);
        } catch { }
        const my = this;
        my.bhSuccessShow.next(!show);
        setTimeout(function () {
            my.bhSuccessShow.next(show);
        }, 200);

        var thread = setTimeout(function () {
            my.bhSuccessShow.next(!show);
        }, 5500);

        this.bhAlertSuccessThread.next(thread);
    }

    changeAlertInfoShow(show: boolean) {
        try {
            var threadTemp;
            this.alertInfoThread.subscribe(thread => threadTemp = thread);
            clearInterval(threadTemp);
        } catch { }
        const my = this;
        my.bhInfoShow.next(!show);
        setTimeout(function () {
            my.bhInfoShow.next(show);
        }, 200);

        var thread = setTimeout(function () {
            my.bhInfoShow.next(!show);
        }, 5500);

        this.bhAlertInfoThread.next(thread);
    }

    hideAlertSuccess() {
        this.bhSuccessShow.next(false);
    }

    changeAlertShowIcon(show: boolean) {
        this.bhAlertShowIcon.next(show);
    }

    changeAlertErrorMessage(message: string) {
        this.bhAlertErrorMessage.next(message);
    }

    changeAlertErrorShow(show: boolean) {
        try {
            var threadTemp;
            this.alertErrorThread.subscribe(thread => threadTemp = thread);
            clearInterval(threadTemp);
        } catch { }
        this.bhAlertErrorShow.next(show);
        const my = this;
        var thread = setTimeout(function () {
            my.bhAlertErrorShow.next(!show);
        }, 5500);

        this.bhAlertErrorThread.next(thread);
    }

    changealertMessage(message: string) {
        this.bhalertMessage.next(message);
    }

    changealertFooter(message: string) {
        this.bhalertFooter.next(message);
    }

    changealertShow(show: boolean) {
        this.bhalertHidden.next(show);
    }

    // ------------------------------------------------------------------------------
}
