// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-wrapper {
  height: 550px;
}
.map-wrapper .sebm-google-map-container {
  height: 100%;
}
.map-wrapper.offset-bottom {
  height: 630px;
}
.map-wrapper.offset-bottom .slide-info {
  padding-bottom: 80px;
}
.map-wrapper.fullscreen {
  height: calc(100vh - (36px + 72px)) !important;
}
.map-wrapper.fullscreen.toolbar-2 {
  height: calc(100vh - 202px) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/header-map/header-map.component.scss","webpack://./src/app/theme/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACI,aCKgB;ADNpB;AAEI;EACI,YAAA;AAAR;AAEI;EACI,aAAA;AAAR;AACQ;EACI,oBCDwB;ADEpC;AAEI;EACI,8CAAA;AAAR;AACQ;EACI,sCAAA;AACZ","sourcesContent":["@import \"../../theme/styles/variables\";\r\n\r\n.map-wrapper{\r\n    height: $header-map-height;\r\n    .sebm-google-map-container{\r\n        height: 100%;\r\n    }\r\n    &.offset-bottom{\r\n        height: $header-map-height + $main-content-header-offset-to-top;        \r\n        .slide-info{\r\n            padding-bottom: $main-content-header-offset-to-top;\r\n        }\r\n    }\r\n    &.fullscreen{\r\n        height: calc(100vh - (#{$top-toolbar-height} + #{$main-toolbar-height})) !important;\r\n        &.toolbar-2{\r\n            height: calc(100vh - 202px) !important; \r\n        }\r\n    }\r\n}\r\n\r\n","$font-family: 'Montserrat';\r\n$theme-max-width: 1300px;\r\n$top-toolbar-height: 36px;\r\n$main-toolbar-height: 72px;\r\n$user-menu-width: 220px;\r\n$header-image-min-height: 320px;\r\n$header-video-min-height: 360px;\r\n$header-carousel-height: 460px;\r\n$header-map-height: 550px;\r\n$main-content-header-offset-to-top: 80px;\r\n\r\n$main-sidenav-width: 250px;\r\n$search-sidenav-width: 288px;"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
