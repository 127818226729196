import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountsLoginRes } from 'src/app/components/interfaces/CRUDL/read/AccountsLoginRes';
import { ExposedHafaSambandReq } from 'src/app/components/interfaces/requests/Exposed/exposedHafaSambandReq';
import { HafaSambandService } from 'src/app/components/services/api/hafaSamband/hafaSamband.service';
import { NotandiService } from 'src/app/components/services/api/notandi/notandi.service';
import { getCookie } from 'src/app/helpers/cookies';
import { ValidateEmail } from 'src/app/helpers/validators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isBrowser: boolean;
  public isServer: boolean;

  // User
  userDetails: AccountsLoginRes;
  sent = false;
  error = false;
  beingSent = false;
  currentYear = new Date().getFullYear();

  public form: UntypedFormGroup;

  kennitala: string = "Óþekkt";
  nafn: string = "";
  simanumer: string = "";
  netfang: string = "";

  constructor(
    private notandiService: NotandiService,
    public formBuilder: UntypedFormBuilder,
    private publicService: HafaSambandService,
    @Inject(PLATFORM_ID) platformId: Object,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    if (this.isBrowser) {
      try {
        this.notandiService.userDetails.subscribe(userDetails => this.userDetails = userDetails);
      } catch { }
    }
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.initForm();
    }
  }

  initForm() {
    if (getCookie('accessToken') && !this.userDetails) {
      this.notandiService.fetchUserDetails().subscribe(user => {
        this.userDetails = user;
        this.kennitala = this.userDetails?.kennitala;
        this.nafn = this.userDetails?.nafn;
        this.simanumer = this.userDetails?.simanumer;
        this.netfang = this.userDetails?.netfang;
        this.initFormData();
      });
    } else {
      this.initFormData();
    }
  }

  initFormData() {
    this.form = this.formBuilder.group({
      kennitala: new FormControl(this.kennitala),
      nafn: new FormControl(this.nafn, [Validators.required]),
      simanumer: new FormControl(this.simanumer, [Validators.required]),
      netfang: new FormControl(this.netfang, [Validators.required, ValidateEmail]),
      skilabod: new FormControl("", [Validators.required])
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.sent = false;
  }

  change() {
    this.error = false;
    this.sent = false;
  }

  public send(): void {
    this.error = false;
    if (this.form.valid) {
      this.beingSent = true;
      const req: ExposedHafaSambandReq = {
        kennitala: this.form.get("kennitala").value,
        nafn: this.form.get("nafn").value,
        netfang: this.form.get("netfang").value,
        simanumer: this.form.get("simanumer").value,
        skilabod: this.form.get("skilabod").value
      };
      this.publicService.hafaSamband(req, false).subscribe(success => {
        this.beingSent = false;
        if (success) {
          this.resetForm();
          this.sent = true;
        } else {
          this.sent = false;
          this.error = true;
        }
      });
    }
  }

  private resetForm(): void {
    this.form.reset();
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).setErrors(null);
      this.form.get(key).markAsPristine();
      this.form.get(key).markAsUntouched();
    });
    this.changeDetectorRef.detectChanges();
  }
}
