import { FormControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

export function ValidatePhone(c: UntypedFormControl) {
  const REGEXP = new RegExp(/^$|^\d{7}$/);
  return REGEXP.test(c.value) ? null : {
    validPhone: true
  };
}

export function ValidateApsUrl(c: UntypedFormControl) {
  const REGEXP = new RegExp(/^$|[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
  return REGEXP.test(c.value) ? null : {
    validEmail: true
  };
}

export function ValidatePhoneSpace(c: UntypedFormControl) {
  const REGEXP = new RegExp(/^$|^\d{3}\s\d{4}$/);
  return REGEXP.test(c.value) ? null : {
    validPhone: true
  };
}

export function ValidateSimanumer(c: FormControl) {
  const REGEXP = new RegExp(/^$|^\d{7}$/);
  return REGEXP.test(c.value) ? null : {
    validSimanumer: true
  };
}

export function ValidateNumber(c: UntypedFormControl) {
  const REGEXP = new RegExp(/^\d+$/);
  return REGEXP.test(c.value) ? null : {
    validNumber: true
  };
}

export function ValidateKennitalaWithHyphen(c: FormControl) {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^([0-3][0-9][0-1][0-9][0-9][0-9])[-]([0-9][0-9][0-9][0-9])$/);
  return REGEXP.test(c.value) ? null : {
    validKennitalaWithHyphen: true
  };
}

export function ValidateCompanyKennitalaWithHyphen(c: FormControl) {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^([0-9][0-9][0-9][0-9][0-9][0-9])[-]([0-9][0-9][0-9][0-9])$/);
  return REGEXP.test(c.value) ? null : {
    validCompanyKennitalaWithHyphen: true
  };
}

export function ValidateCompanyKennitala(c: FormControl) {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^([0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])$/);
  return REGEXP.test(c.value) ? null : {
    validateCompanyKennitala: true
  };
}

export function ValidateKennitala(c: FormControl) {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^([0-3][0-9][0-1][0-9][0-9][0-9][0-9][0-9][0-9][0-9])$/);
  return REGEXP.test(c.value) ? null : {
    validKennitala: true
  };
}

export function ValidateAnyKennitala(c: FormControl) {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^([0-7][0-9][0-1][0-9][0-9][0-9][0-9][0-9][0-9][0-9])$/);
  return REGEXP.test(c.value) ? null : {
    validKennitala: true
  };
}

export function ValidateMinute(c: FormControl) {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^([0-5]?\d)$/);
  return REGEXP.test(c.value) ? null : {
    validMinute: true
  };
}

export function ValidateHour(c: FormControl) {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^([01]?\d|2[0-3])$/);
  return REGEXP.test(c.value) ? null : {
    validHour: true
  };
}

export function ValidateEmail(c: FormControl): ValidationErrors {
  // tslint:disable-next-line:max-line-length
  const REGEXP = new RegExp(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return REGEXP.test(c.value) ? null : {
    validEmail: true
  };
}

export function ValidateHlutfall(c: FormControl) {
  const REGEXP = new RegExp(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/);
  return REGEXP.test(c.value) ? null : {
    validHlutfall: true
  };
}

export function ValidateKennitala2(c: FormControl) {
  let rett = false;
  if (c.value === '0612933459' || c.value === '3001922469') {
    rett = true;
  }
  return rett ? null : {
    validKennitala: true
  };
}

export function ValidateRadstafadEignarhlutfall(c: FormControl) {
  let rett = false;
  if (c.value === 100) {
    rett = true;
  }
  return rett ? null : {
    validRadstafadEignarhlutfall: true
  };
}

export function ValidateRadstofunarfjarhaed(c: FormControl) {
  let rett = false;
  if (c.value === 0) {
    rett = true;
  }
  return rett ? null : {
    validRadstofunarFjarhaed: true
  };
}

export function ValidateNotZero(c: FormControl) {
  const REGEXP = new RegExp(/^[0]+$/);
  return REGEXP.test(c.value) ? null : {
    validNotZero: true
  };
}

export function ValidateChecked(c: FormControl) {
  let rett = false;
  if (c.value === true) {
    rett = true;
  }
  return rett ? null : {
    isChecked: true
  };
}

export function ForsidumyndCheck(c: FormControl) {
  let rett = false;
  if (c.value !== 0) {
    rett = true;
  }
  return rett ? null : {
    validForsidumynd: true
  };
}
