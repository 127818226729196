import { AlertsService } from "../components/services/system/alerts/alerts.service";

export function HttpError(alertService: AlertsService, router, error, header429: string, message429: string, header401: string, message401: string) {
  header429 = (header429 == null || header429 == "") ? "Of margar fyrirspurnir sendar!" : header429;
  message429 = (message429 == null || message429 == "") ? "Öll misnotkun er skráð og getur leitt til brottvísunar (og/eða bótakröfu)." : message429;
  header401 = (header401 == null || header401 == "") ? "Þú þarft að auðkenna þig" : header401;
  message401 = (message401 == null || message401 == "") ? "Þú átt eftir að innskrá þig eða auðkenningin þín er runnin út. Vinsamlegast innskráðu þig." : message401;

  console.log(error);
  if (error) {
    if (error.status) {
      if (error.status == 429) {
        alertService.changealertHeaderMessageFooter(header429, message429, error.error);
      } if (error.status == 401) {

      } else if (!error.error) {
        alertService.changealertHeaderMessageFooter("Óvænt villa kom upp", "Vinsamlegast reynið að endurræsa síðu og/eða út- og innskrá.", "Hafið samband við kerfisstjórn ef á þarf.");
      } else {
        alertService.changealertHeaderMessageFooter(error.error.errorItem.friendlyMessage, error.error.errorItem.message, error.error.errorItem.innerMessage);
      }
    } else {
      alertService.changealertHeaderMessageFooter("Óvænt villa kom upp", "Vinsamlegast reynið að endurræsa síðu og/eða út- og innskrá.", "Hafið samband við kerfisstjórn ef á þarf.");
    }
  } else {
    alertService.changealertHeaderMessageFooter("Óvænt villa kom upp", "Vinsamlegast reynið að endurræsa síðu og/eða út- og innskrá.", "Hafið samband við kerfisstjórn ef á þarf.");
  }
}
