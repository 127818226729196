import { leadingZeros } from './leadingZeros';

export function opidHusString(date: Date, dateTo: Date) {
  const currentDate = new Date();
  let dags = " " + date.getUTCDate().toString() + "." + (date.getMonth() + 1).toString() + "." + date.getUTCFullYear().toString();
  if (currentDate.getUTCFullYear() == date.getUTCFullYear() && currentDate.getUTCMonth() == date.getUTCMonth() && currentDate.getUTCDate() == date.getUTCDate()) {
    dags = "í dag"
  } else if (date.getUTCFullYear() == 1) {
    return "";
  }
  if (new Date(date) < currentDate) {
    return "";
  }

  return "Opið hús " + dags + " kl. " + leadingZeros(date.getHours(), 2).toString() + ":" + leadingZeros(date.getMinutes(), 2).toString() + " - " + leadingZeros(dateTo.getHours(), 2).toString() + ":" + leadingZeros(dateTo.getMinutes(), 2).toString();
}
