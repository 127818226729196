export const environment = {
  production: false,
  url: '',
  env: 'test',
  localApi: 'https://api-test.e-fasteignir.is/api/',
  portalUrl: 'https://heimabanki-test.e-fasteignir.is',
  googleMapsApiKey: 'AIzaSyA5-ZLb5n1WSygocxfRK8m-et8H7MeVgZ4',
  rootPublicUrl: 'https://test.e-fasteignir.is',
  fileServiceUrl: "https://fileservice.e-fasteignir.is/Test/",
  fileServiceRootUrl: "https://fileservice.e-fasteignir.is/",
};
