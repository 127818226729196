// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.get-in-touch {
  position: relative;
  margin-top: 80px;
}
.get-in-touch img {
  max-width: 200px;
  position: absolute;
  bottom: 0;
}
.get-in-touch .content {
  padding-left: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/get-in-touch/get-in-touch.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;AACJ;AAAI;EACI,gBAAA;EACA,kBAAA;EACA,SAAA;AAER;AAAI;EACI,mBAAA;AAER","sourcesContent":[".get-in-touch{\r\n    position: relative;\r\n    margin-top: 80px;\r\n    img{\r\n        max-width: 200px; \r\n        position: absolute; \r\n        bottom: 0;\r\n    }\r\n    .content{\r\n        padding-left: 200px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
