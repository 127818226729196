// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 960px) {
  .fuse-confirmation-dialog-panel {
    width: 32rem;
  }
}
.fuse-confirmation-dialog-panel .mat-dialog-container {
  padding: 0 !important;
}

#c-divider {
  margin: 15px 0px 5px 0px !important;
}

#c-success-alert {
  margin-top: 15px !important;
}

pdf-viewer {
  display: block;
}

.fa-file-download {
  margin-right: 5px;
}

.mat-dialog-content {
  background: white !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.c-dialog-content {
  padding: 0px !important;
  overflow: hidden !important;
}

#viewerContainer {
  top: 30px !important;
}

#c-close {
  position: relative;
  top: -5px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/dialog-pdf/dialog-pdf.scss"],"names":[],"mappings":"AACE;EACE;IAAA;EAAA;AAEJ;AACE;EACE,qBAAA;AACJ;;AAGA;EACE,mCAAA;AAAF;;AAGA;EACE,2BAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,4BAAA;EAIA,8DAAA;AAJF;;AAOA;EACE,uBAAA;EACA,2BAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;AAAF","sourcesContent":[".fuse-confirmation-dialog-panel {\r\n  @screen md {\r\n    @apply w-128;\r\n  }\r\n\r\n  .mat-dialog-container {\r\n    padding: 0 !important;\r\n  }\r\n}\r\n\r\n#c-divider {\r\n  margin: 15px 0px 5px 0px !important;\r\n}\r\n\r\n#c-success-alert {\r\n  margin-top: 15px !important;\r\n}\r\n\r\npdf-viewer {\r\n  display: block;\r\n}\r\n\r\n.fa-file-download {\r\n  margin-right: 5px;\r\n}\r\n\r\n.mat-dialog-content {\r\n  background: white !important;\r\n}\r\n\r\n.mat-dialog-content {\r\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;\r\n}\r\n\r\n.c-dialog-content {\r\n  padding: 0px !important;\r\n  overflow: hidden !important;\r\n}\r\n\r\n#viewerContainer {\r\n  top: 30px !important;\r\n}\r\n\r\n#c-close {\r\n  position: relative;\r\n  top: -5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
