import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { environment } from 'src/environments/environment';
import { PagesComponent } from './components/pages/pages.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent, children: [
      //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
      { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule) },
      { path: 'skrauremaillista/:token', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule) },
      { path: 'personuverndarstefna', loadChildren: () => import('./pages/personuverndarstefna/personuverndarstefna.module').then(m => m.PersonuverndarstefnaModule) },
      { path: 'um-okkur', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
      { path: 'notendaskilmalar', loadChildren: () => import('./pages/userterms/userterms.module').then(m => m.UserTermsModule) },
      { path: 'hafa-samband', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
      { path: 'fasteignir/til-solu', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
      { path: 'eftirlitsnefnd-fasteignasala', loadChildren: () => import('./pages/eftirlitsnefnd-fasteignasala/eftirlitsnefnd-fasteignasala.module').then(m => m.EftirlitsnefndFasteignasalaModule) },
      { path: 'gjaldskra', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },

      // Passa að matcha einnig url frá gamla vefsvæði:
      { path: 'forsida/fasteignir-til-solu/fasteign', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
      { path: 'forsida/um-okkur/notendaskilmalar', loadChildren: () => import('./pages/userterms/userterms.module').then(m => m.UserTermsModule) },
      { path: 'forsida/um-okkur/personuverndarstefna', loadChildren: () => import('./pages/personuverndarstefna/personuverndarstefna.module').then(m => m.PersonuverndarstefnaModule) },
      { path: 'forsida/fyrirspurn-eftirlitsnefndar-fasteignasala/yfirlit', loadChildren: () => import('./pages/eftirlitsnefnd-fasteignasala/eftirlitsnefnd-fasteignasala.module').then(m => m.EftirlitsnefndFasteignasalaModule) },
      { path: 'forsida/um-okkur/gjaldskra', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },
      { path: 'forsida/um-okkur/almennt', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
      {
        path: 'forsida/um-okkur/almennt',
        component: PagesComponent,
        canActivate: [],
        resolve: {
          redirect: () => {
            window.location.href = environment.rootPublicUrl + "/um-okkur";
            return false;
          }
        }
      },
      {
        path: 'forsida/login',
        component: PagesComponent,
        canActivate: [],
        resolve: {
          redirect: () => {
            window.location.href = environment.portalUrl;
            return false;
          }
        }
      },
      {
        path: 'forsida/tilbod/kaup-og-gangtilbod/kauptilbod/:fasteignId',
        component: PagesComponent,
        canActivate: [],
        resolve: {
          redirect: (route: ActivatedRouteSnapshot) => {
            const fasteignId = route.params['fasteignId'];
            window.location.href = `${environment.portalUrl}/kaupa/nytt-kauptilbod/${fasteignId}`;
            return false;
          }
        }
      }
    ]
  },
  { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
  { path: 'lock-screen', component: LockScreenComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      initialNavigation: 'enabledBlocking', // for one load page, without reload
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
