export function thousandSeparator(numberString: string) {
  const parts = numberString.split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const decimalPart = parts[1] ? ',' + parts[1] : '';
  return integerPart + decimalPart;
}

export function formatPriceString(s: string) {
  var formattedString = '';
  formattedString = s.replace(/\./g, '');
  formattedString = formattedString.replace(/[^0-9.]/g, '');
  return formattedString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
