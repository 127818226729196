export function firstname(fullname: string){
    return fullname.split(" ")[0];
}

export function goodDayEvening(){
    let returnitem = "Góðan daginn";
    var today = new Date().getHours();
    if (today >= 18 || today <= 4) {
       return "Góða kvöldið"
    } else {
        return "Góðan daginn";
    }
}