import * as moment from 'moment';
import 'moment-timezone';

/**
 * Makes sure to keep date on GMT+00 format even though the local machine has another timezone set.
 * @param date Date that should be converted
 * @returns
 */
export function dateToGMT(date: string | Date): string {
    return moment(date).parseZone().tz("Atlantic/Reykjavik", true).toISOString(true);
}
