export function formatSsn(ssn: string) {
  if (ssn == null) {
    return "";
  }
  if (ssn.length > 6) {
    ssn = ssn.replace('-', '');
    ssn = ssn.substring(0, 6) + '-' + ssn.substring(6, 10);
  }
  return ssn;
}

export function formatPhoneNumer(phone: string): string {
  if (phone == null) {
    return "";
  }
  let _phone = phone.replace(/ /g, '')
  if (_phone.length > 3) {
    _phone = _phone.substring(0, 3) + " " + _phone.substring(3)
  }
  return _phone;
}

