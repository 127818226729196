// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonials-carousel .swiper-container {
  padding-bottom: 50px;
}
.testimonials-carousel .swiper-container .content {
  max-width: 650px;
  margin: 0 auto;
}
.testimonials-carousel .swiper-container .content img {
  border-radius: 50%;
  width: 140px;
  height: 140px;
}
.testimonials-carousel .swiper-container .content .quote {
  font-size: 50px;
  line-height: 14px;
}
.testimonials-carousel .swiper-container .content .quote.open {
  margin-top: 24px;
}
.testimonials-carousel .swiper-container .content .quote.close {
  margin-bottom: 24px;
}
.testimonials-carousel .swiper-container .content .text {
  font-weight: 500;
}
.testimonials-carousel .swiper-container .content .author {
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/testimonials/testimonials.component.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;AAAR;AACQ;EACI,gBAAA;EACA,cAAA;AACZ;AAAY;EACI,kBAAA;EACA,YAAA;EACA,aAAA;AAEhB;AAAY;EACI,eAAA;EACA,iBAAA;AAEhB;AADgB;EACI,gBAAA;AAGpB;AADgB;EACI,mBAAA;AAGpB;AAAY;EACI,gBAAA;AAEhB;AAAY;EACI,yBAAA;AAEhB","sourcesContent":[".testimonials-carousel{    \r\n    .swiper-container{\r\n        padding-bottom: 50px;\r\n        .content{\r\n            max-width: 650px;\r\n            margin: 0 auto; \r\n            img{\r\n                border-radius: 50%;\r\n                width: 140px;\r\n                height: 140px;\r\n            }\r\n            .quote{\r\n                font-size: 50px; \r\n                line-height: 14px; \r\n                &.open{\r\n                    margin-top: 24px;\r\n                }\r\n                &.close{\r\n                    margin-bottom: 24px;\r\n                }           \r\n            }\r\n            .text{ \r\n                font-weight: 500; \r\n            }\r\n            .author{\r\n                text-transform: uppercase;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
