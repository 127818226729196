// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.box .box-header {
  height: 180px;
}
.box .box-header .error {
  font-size: 48px;
  margin-bottom: 12px;
}
.box .box-content {
  position: relative;
  height: 180px;
}
.box .box-content .box-content-inner {
  position: absolute;
  top: -34px;
  left: 34px;
  right: 34px;
  height: 180px;
  border-radius: 4px;
}
.box .box-content .box-content-header {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}
.box .box-content .box-content-header.server-error {
  margin-bottom: 36px;
}
.box .box-content .box-text {
  margin-bottom: 10px;
  text-align: center;
}
.box .box-content .box-text::last-child {
  margin-bottom: 15px;
}
.box .box-content .box-footer {
  position: relative;
  bottom: 16px;
}
.box .box-content .box-footer button {
  min-width: 70px;
  margin: 0 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAAI;EACI,aAAA;AAER;AADQ;EACI,eAAA;EACA,mBAAA;AAGZ;AAAI;EACI,kBAAA;EACA,aAAA;AAER;AADQ;EACI,kBAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAGZ;AADQ;EACI,eAAA;EACA,yBAAA;EACA,gBAAA;AAGZ;AAFY;EACI,mBAAA;AAIhB;AADQ;EACI,mBAAA;EACA,kBAAA;AAGZ;AAFY;EACI,mBAAA;AAIhB;AADQ;EACI,kBAAA;EACA,YAAA;AAGZ;AAFY;EACI,eAAA;EACA,aAAA;AAIhB","sourcesContent":[".box {\r\n    position: relative;\r\n    overflow: hidden;\r\n    border-radius: 4px;\r\n    .box-header{\r\n        height: 180px;\r\n        .error{\r\n            font-size: 48px;\r\n            margin-bottom: 12px;\r\n        }\r\n    }\r\n    .box-content{\r\n        position: relative;\r\n        height: 180px;     \r\n        .box-content-inner{\r\n            position: absolute;\r\n            top: -34px;\r\n            left: 34px;\r\n            right: 34px;\r\n            height: 180px;\r\n            border-radius: 4px;\r\n        }\r\n        .box-content-header{\r\n            font-size: 16px; \r\n            text-transform: uppercase;\r\n            font-weight:500;\r\n            &.server-error{\r\n                margin-bottom: 36px;\r\n            }\r\n        }\r\n        .box-text{\r\n            margin-bottom: 10px;\r\n            text-align: center;\r\n            &::last-child{\r\n                margin-bottom: 15px;\r\n            }\r\n        }\r\n        .box-footer{\r\n            position: relative;\r\n            bottom: 16px;\r\n            button{\r\n                min-width: 70px;\r\n                margin: 0 2px;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
