import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ExposedHafaSambandReq } from 'src/app/components/interfaces/requests/Exposed/exposedHafaSambandReq';
import { ExposedSendaFasteignasalaFyrirspurnReq } from 'src/app/components/interfaces/requests/Exposed/exposedSendaFasteignasalaFyrirspurnReq';
import { HttpError } from 'src/app/helpers/httpError';
import { HttpRequestHelper } from 'src/app/helpers/httpRequestHelper';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../../system/alerts/alerts.service';
import { LoaderService } from '../../system/loader/loader.service';
import { NotandiService } from '../notandi/notandi.service';

@Injectable({
  providedIn: 'root'
})
export class HafaSambandService {

  private httpRequestHelper = new HttpRequestHelper();

  constructor(private notandiService: NotandiService, private http: HttpClient, private loaderService: LoaderService, private alertsService: AlertsService, private router: Router, public dialog: MatDialog) { }

  public SendaFasteignasalaFyrirspurn(req: ExposedSendaFasteignasalaFyrirspurnReq): Observable<null> {
    let subject = new Subject<null>();
    this.http.post(environment.localApi + 'HafaSamband/sendaFasteignasalaFyrirspurn', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Fyrirspurn hefur verið send");
        this.alertsService.changeAlertSuccessShow(true);
      },
      error => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public hafaSamband(req: ExposedHafaSambandReq, showSuccess = true): Observable<boolean> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sendi fyrirspurn');
    let subject = new Subject<boolean>();

    this.http.post(environment.localApi + 'HafaSamband/sendaFyrirspurn', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        if (showSuccess) {
          this.alertsService.changeAlertShowIcon(true);
          this.alertsService.changeAlertSuccessMessage("Skilaboðin voru send. Við höfum samband við fyrsta tækifæri 😉");
          this.alertsService.changeAlertSuccessShow(true);
        }
        subject.next(true);
      },
      (error) => {
        subject.next(false);
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }
}
