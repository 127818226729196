import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpError } from 'src/app/helpers/httpError';
import { HttpRequestHelper } from 'src/app/helpers/httpRequestHelper';
import { firstname } from 'src/app/helpers/names';

import { AccountsLoginRes } from 'src/app/components/interfaces/CRUDL/read/AccountsLoginRes';
import { ExposedConfirmEmailAndTermsReq } from 'src/app/components/interfaces/requests/Exposed/exposedConfirmEmailAndTermsReq';
import { ExposedHasBreytaAccessReq } from 'src/app/components/interfaces/requests/Exposed/exposedHasBreytaAccessReq';
import { ExposedUserSettingsEditReq } from 'src/app/components/interfaces/requests/Exposed/exposedUserSettingsEditReq';
import { ListClaimsReq } from 'src/app/components/interfaces/requests/List/listClaimReq';
import { ListUserFasteignasolurRelationsReq } from 'src/app/components/interfaces/requests/List/listUserFasteignasolurRelationsReq';
import { ExposedClaimDetailsRes } from 'src/app/components/interfaces/responses/Exposed/exposedClaimDetailsRes';
import { ExposedConfirmEmailRes } from 'src/app/components/interfaces/responses/Exposed/exposedConfirmEmailRes';
import { ExposedGetFasteignStateAndAccessRes } from 'src/app/components/interfaces/responses/Exposed/exposedGetFasteignStateAndAccessRes';
import { ExposedReadProfilmyndRes } from 'src/app/components/interfaces/responses/Exposed/exposedReadProfilmyndRes';
import { ListClaimsRes } from 'src/app/components/interfaces/responses/List/listClaimRes';
import { ListFasteignasolurUsersRes } from 'src/app/components/interfaces/responses/List/listFasteignasolurUsersRes';
import { ListUserFasteignasolurRelationsRes } from 'src/app/components/interfaces/responses/List/listUserFasteignasolurRelationsRes';
import { ListUsersRes } from 'src/app/components/interfaces/responses/List/listUsersRes';
import { getCookie, setCookie } from 'src/app/helpers/cookies';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../../system/alerts/alerts.service';
import { LoaderService } from '../../system/loader/loader.service';
import { TilkynningarService } from '../tilkynningar/tilkynningar.service';

@Injectable({
  providedIn: 'root',
})
export class NotandiService {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private alertsService: AlertsService,
    private router: Router,
    private tilkynningarService: TilkynningarService
  ) { }

  // Logged in user details (local)
  private bhUserDetails = new BehaviorSubject<ExposedClaimDetailsRes>(null);
  userDetails = this.bhUserDetails.asObservable();

  changeUserDetails(user: ExposedClaimDetailsRes) {
    this.bhUserDetails.next(user);
  }

  // Fasteignasölur notanda
  private bhFasteignasolurNotanda = new BehaviorSubject<Array<ListFasteignasolurUsersRes>>(null);
  fasteignasolurNotanda = this.bhFasteignasolurNotanda.asObservable();

  changeFasteignasolurNotanda(fasteignasolur: Array<ListFasteignasolurUsersRes>) {
    this.bhFasteignasolurNotanda.next(fasteignasolur);
  }

  // Valin fasteignasala notanda
  private bhValinFasteignasala = new BehaviorSubject<ListFasteignasolurUsersRes>(null);
  valinFasteignasala = this.bhValinFasteignasala.asObservable();

  changeValinFasteignasala(fasteignasala: ListFasteignasolurUsersRes) {
    this.bhValinFasteignasala.next(fasteignasala);
  }

  // Segir til um hvort við eigum að sína sölustillingar í menu
  private bhShowSolustillingar = new BehaviorSubject<boolean>(false);
  showSolustillingar = this.bhShowSolustillingar.asObservable();

  changeShowSolustillingar(show: boolean) {
    this.bhShowSolustillingar.next(show);
  }

  // Segir til um hvort búið sé að birta staðfesta skilmála modal
  // því við viljum ekki að refresh token birti modalinn ef login
  // er nú þegar búið að birta modal.
  private displayStadfesta = false;

  private httpRequestHelper = new HttpRequestHelper();

  private bhshowLoginMenu = new BehaviorSubject(false);
  /**
   * Segir til um hvort sýna eigi logged-in menu.
   */
  showLoginMenu = this.bhshowLoginMenu.asObservable();

  /**
   * Breyta því hvort notandi sér logged-in menu.
   * @param show sýna login menu
   */
  changeShowLoginMenu(show: boolean) {
    this.bhshowLoginMenu.next(show);
  }

  private bhLoginRedirect = new BehaviorSubject('');
  /**
   * Segir til um hvort sýna eigi logged-in menu.
   */
  loginRedirect = this.bhLoginRedirect.asObservable();

  /**
   * Breyta því hvort notandi sér logged-in menu.
   * @param show sýna login menu
   */
  changeLoginRedirect(url: string) {
    this.bhLoginRedirect.next(url);
  }

  private bhLoggedIn = new BehaviorSubject(false);
  /**
   * Segir til um hvort sýna eigi loader.
   */
  loggedIn = this.bhLoggedIn.asObservable();

  /**
   * Breyta því hvort notandi sé skráður inn eða ekki.
   * @param loggedIn sýna loader
   */
  changeLoggedInLogout(loggedIn: boolean) {
    this.bhLoggedIn.next(loggedIn);
    if (!loggedIn) {
      this.router.navigateByUrl('/forsida/login');
    }
  }

  changeLoggedIn(loggedIn: boolean) {
    this.bhLoggedIn.next(loggedIn);
  }

  private bhAccountsLoginRes = new BehaviorSubject<AccountsLoginRes>(null);

  public confirmEmailAndTerms(req: ExposedConfirmEmailAndTermsReq): Observable<null> {
    let subject = new Subject<null>();

    this.http.post(environment.localApi + 'NotandiAudkenning/confirmEmailAndTerms', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(null);
        this.alertsService.changeAlertShowIcon(true);
        this.alertsService.changeAlertSuccessMessage("Tölvupóstur var sendur");
        this.alertsService.changeAlertSuccessShow(true);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {

    });
    return subject.asObservable();
  }

  public confirmEmail(userId: number, token: string): Observable<ExposedConfirmEmailRes> {
    let subject = new Subject<ExposedConfirmEmailRes>();

    this.http.get(environment.localApi + 'NotandiAudkenning/confirmEmail/' + userId + "/" + token, {}).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        subject.next(null);
      }
    ).add(() => {

    });
    return subject.asObservable();
  }

  public fetchUserDetails(showLoader = true): Observable<ExposedClaimDetailsRes> {
    let subject = new Subject<ExposedClaimDetailsRes>();
    try {
      if (showLoader) {
        this.loaderService.changeActiveLoaders(1);
      }
      this.http
        .get(
          environment.localApi + 'NotandiAudkenning/claimDetails',
          this.httpRequestHelper.getRequestOptionsAuth()
        )
        .subscribe(
          (res: any) => {
            this.changeUserDetails(res.returnItem);
            subject.next(res.returnItem);
          },
          (error) => {
            subject.next(null);
          }
        )
        .add(() => {
          if (showLoader) {
            this.loaderService.changeActiveLoaders(-1);
          }
        });
    } catch { subject.next(null); }
    return subject.asObservable();
  }

  public fetchClaimDetails(): Observable<ExposedClaimDetailsRes> {
    return this.fetchUserDetails();
  }

  public veljaFasteignasolu(fasteignasalaId: number): Observable<null> {
    let subject = new Subject<null>();
    const req: ExposedUserSettingsEditReq = {
      GetTilkynningarEmail: null,
      Netfang: null,
      FasteignasolurId: fasteignasalaId,
      Heimilisfang: null,
      Postnumer: null,
      Sveitarfelag: null,
      ThinglystSkjalEkkiSpyrjaSott: null,
      Locked: null,
      Employee: null,
      SamthykkiSkilmala: null,
      SendConfirmEmail: null
    };
    this.http
      .post(
        environment.localApi +
        'NotandiStillingar/updateUserInformation',
        req,
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        () => {
          this.fetchUserDetails();
          subject.next(null);
        },
        (error) => {
          HttpError(
            this.alertsService,
            this.router,
            error,
            '',
            '',
            '',
            ''
          );
        }
      )
      .add(() => {
      });
    return subject.asObservable();
  }

  public userSettingsEdit(
    req: ExposedUserSettingsEditReq,
    loaderMessage = 'Notendastillingar vistaðar',
    showAlert = true,
    showLoader = true
  ): Observable<null> {
    let subject = new Subject<null>();
    if (showLoader) {
      this.loaderService.changeActiveLoaders(1);
      this.loaderService.changeLoaderMessage('Vista upplýsingar');
    }
    this.http
      .post(
        environment.localApi +
        'NotandiStillingar/updateUserInformation',
        req,
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        () => {
          if (showAlert) {
            this.alertsService.changeAlertShowIcon(true);
            this.alertsService.changeAlertSuccessMessage("Prófílupplýsingar vistaðar");
            this.alertsService.changeAlertSuccessShow(true);
          }
          subject.next(null);
        },
        (error) => {
          HttpError(
            this.alertsService,
            this.router,
            error,
            '',
            '',
            '',
            ''
          );
        }
      )
      .add(() => {
        if (showLoader) {
          this.loaderService.changeActiveLoaders(-1);
        }
      });
    return subject.asObservable();
  }

  public readProfilmynd(): Observable<ExposedReadProfilmyndRes> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki upplýsingar');
    let subject = new Subject<ExposedReadProfilmyndRes>();

    this.http
      .get(
        environment.localApi + 'NotandiStillingar/profilmynd/read',
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          subject.next(res.returnItem);
        },
        (error) => {
          HttpError(
            this.alertsService,
            this.router,
            error,
            '',
            '',
            '',
            ''
          );
        }
      )
      .add(() => {
        this.loaderService.changeActiveLoaders(-1);
      });
    return subject.asObservable();
  }

  public listClaims(req: ListClaimsReq): Observable<Array<ListClaimsRes>> {
    let subject = new Subject<Array<ListClaimsRes>>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage("Sæki reikninga");

    this.http.post(environment.localApi + 'NotandiStillingar/list/claims', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public getNetfang(kennitala: string): Observable<string> {
    let subject = new Subject<string>();

    this.http
      .get(
        environment.localApi + 'NotandiStillingar/getNetfang/' + kennitala,
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          subject.next(res.returnItem);
        },
        (error) => {
          console.log(error);
        }
      )
      .add(() => {
      });
    return subject.asObservable();
  }

  public updateUserProfileImage(file: File, userId: number, showAlert = true): Observable<null> {
    let subject = new Subject<null>();
    var uploadFiles = new FormData();
    uploadFiles.append('file', file);
    this.loaderService.changeActiveLoaders(1);
    this.http.post(environment.localApi + 'NotandiStillingar/profilmynd/update/' + userId, uploadFiles, this.httpRequestHelper.getRequestOptionsAuthFileUpload()).subscribe(
      (res: any) => {
        if (showAlert) {
          this.alertsService.changeAlertShowIcon(true);
          this.alertsService.changeAlertSuccessMessage("Prófílmynd uppfærð");
          this.alertsService.changeAlertSuccessShow(true);
        }
        subject.next(null);
      },
      (error) => {
        subject.next(null);
        HttpError(
          this.alertsService,
          this.router,
          error,
          '',
          '',
          '',
          ''
        );
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });


    return subject.asObservable();
  }

  public segjaSigUr(): Observable<object> {
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Vista upplýsingar');
    let subject = new Subject<object>();

    this.http
      .post(
        environment.localApi +
        'NotandiStillingar/segjaSigUrFasteignasolu',
        null,
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          subject.next(res.returnItem);
          this.alertsService.changeAlertShowIcon(true);
          this.alertsService.changeAlertSuccessMessage(
            'Aðgerð tókst'
          );
          this.alertsService.changeAlertSuccessShow(true);
        },
        (error) => {
          HttpError(
            this.alertsService,
            this.router,
            error,
            '',
            '',
            '',
            ''
          );
        }
      )
      .add(() => {
        this.loaderService.changeActiveLoaders(-1);
      });
    return subject.asObservable();
  }

  // Passar að notandaupplýsingar hafi verið skilað áður en þær eru notaðar í angular componentum.
  public checkAuthInfo(): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.loaderService.changeActiveLoaders(1);
    this.http
      .get(
        environment.localApi +
        'NotandiAudkenning/' +
        getCookie('refreshToken') +
        '/refreshToken',
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          this.bhAccountsLoginRes.next(res.returnItem);
          setCookie('accessToken', res.returnItem.accessToken, 7);
          setCookie('refreshToken', res.returnItem.refreshToken, 7);
          subject.next(true);
        },
        (error) => {
          if (error.status == 429) {
            this.alertsService.changealertHeaderMessageFooter(
              'Of margar fyrirspurnir sendar!',
              'Öll misnotkun er skráð og leiðir til brottvísunar.',
              error.error
            );
          }
          console.log(error);
          setCookie('accessToken', '', 7);
          setCookie('refreshToken', '', 7);
          subject.next(false);
        }
      )
      .add(() => {
        this.loaderService.changeActiveLoaders(-1);
      });
    return subject.asObservable();
  }

  public listUsers(): Observable<Array<ListUsersRes>> {
    let subject = new Subject<Array<ListUsersRes>>();
    this.loaderService.changeActiveLoaders(1);
    this.loaderService.changeLoaderMessage('Sæki notendur');
    this.http
      .get(
        environment.localApi + 'Notandi/listUsers',
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          subject.next(res.returnItem);
        },
        (error) => {
          HttpError(
            this.alertsService,
            this.router,
            error,
            '',
            '',
            '',
            ''
          );
        }
      )
      .add(() => {
        this.loaderService.changeActiveLoaders(-1);
      });
    return subject.asObservable();
  }

  public changeClaimInfo(kennitala: string): Observable<AccountsLoginRes> {
    this.loaderService.changeActiveLoaders(1);
    let subject = new Subject<AccountsLoginRes>();
    this.loaderService.changeLoaderMessage(
      'Kanna upplýsingar um innskráðan notanda'
    );
    this.http
      .get(
        environment.localApi +
        'NotandiAudkenning/changeClaimInfo/' +
        kennitala,
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          this.bhAccountsLoginRes.next(res.returnItem);
          setCookie('accessToken', res.returnItem.accessToken, 7);
          setCookie('refreshToken', res.returnItem.refreshToken, 7);
          this.changeLoggedInLogout(true);
          subject.next(res.returnItem);
          this.alertsService.changeAlertShowIcon(false);
          this.alertsService.changeAlertSuccessMessage(
            'Notandi, ' +
            firstname(res.returnItem.nafn) +
            ', hefur verið valinn'
          );
          this.alertsService.changeAlertSuccessShow(true);
        },
        (error) => {
          if (error.status == 429) {
            this.alertsService.changealertHeaderMessageFooter(
              'Of margar fyrirspurnir sendar!',
              'Öll misnotkun er skráð og leiðir til brottvísunar.',
              error.error
            );
          }
          console.log(error);
          setCookie('accessToken', '', 7);
          setCookie('refreshToken', '', 7);
          this.changeLoggedInLogout(false);
        }
      )
      .add(() => {
        this.loaderService.changeActiveLoaders(-1);
      });
    return subject.asObservable();
  }

  public fasteignErAbyrgdaradiliEdaAdmin(
    fasteignSkraningId: number
  ): Observable<boolean> {
    this.loaderService.changeActiveLoaders(1);
    let subject = new Subject<boolean>();
    this.loaderService.changeLoaderMessage('Kanna aðgangsréttindi notanda');
    this.http
      .get(
        environment.localApi +
        'NotandiAccessRoles/FasteignErAbyrgdaradiliEdaAdmin/' +
        fasteignSkraningId,
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          subject.next(res.returnItem);
        },
        (error) => {
          HttpError(
            this.alertsService,
            this.router,
            error,
            '',
            '',
            '',
            ''
          );
        }
      )
      .add(() => {
        this.loaderService.changeActiveLoaders(-1);
      });
    return subject.asObservable();
  }

  public hasBreytaAccess(
    req: ExposedHasBreytaAccessReq
  ): Observable<boolean> {
    let subject = new Subject<boolean>();
    this.loaderService.changeActiveLoaders(1);
    this.http
      .post(
        environment.localApi + 'NotandiAccessRoles/hasBreytaAccess',
        req,
        this.httpRequestHelper.getRequestOptionsAuth()
      )
      .subscribe(
        (res: any) => {
          subject.next(res);
        },
        (error) => {
          HttpError(
            this.alertsService,
            this.router,
            error,
            '',
            '',
            '',
            ''
          );
        }
      )
      .add(() => {
        this.loaderService.changeActiveLoaders(-1);
      });
    return subject.asObservable();
  }

  public getFasteignStateAndAccess(fasteignSkraningId: number, loaderMessage: string = ""): Observable<ExposedGetFasteignStateAndAccessRes> {
    let subject = new Subject<ExposedGetFasteignStateAndAccessRes>();
    this.loaderService.changeActiveLoaders(1);
    if (loaderMessage) {
      this.loaderService.changeLoaderMessage(loaderMessage);
    }

    this.http.get(environment.localApi + 'NotandiAccessRoles/getFasteignStateAndAccess/' + fasteignSkraningId, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public getUserFasteignasolurRelations(req: ListUserFasteignasolurRelationsReq, loaderMessage: string = ""): Observable<Array<ListUserFasteignasolurRelationsRes>> {
    let subject = new Subject<Array<ListUserFasteignasolurRelationsRes>>();
    this.loaderService.changeActiveLoaders(1);
    if (loaderMessage) {
      this.loaderService.changeLoaderMessage(loaderMessage);
    }

    this.http.post(environment.localApi + 'NotandiAccessRoles/getUserFasteignasolurRelations', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.loaderService.changeActiveLoaders(-1);
    });
    return subject.asObservable();
  }

  public skraUrEmaillista(token: string) {
    this.http.get(environment.localApi + 'Tilkynningar/skraUrEmailLista/' + token, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      () => {
      },
      (error) => {
        HttpError(this.alertsService, this.router, error, "", "", "", "");
      }
    ).add(() => {
      this.router.navigateByUrl('/');
    });
  }

}


function DialogSamthykkjaSkilmala(
  DialogSamthykkjaSkilmala: any,
  arg1: {
    autoFocus: false;
    restoreFocus: false;
    width: string;
    data: { accountsLoginRes: AccountsLoginRes };
  }
) {
  throw new Error('Function not implemented.');
}
