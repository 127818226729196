import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { base64ToArrayBuffer, saveByteArray } from 'src/app/helpers/blobDownload';
import { DialogGeneral } from '../DialogGeneral';

export interface DialogPdfData {
  header: string;
  // Má vera src eða base64 src
  pdfSrc: string;
  fileName: string;
  isBase64: boolean;
}

@Component({
  selector: 'dialog-pdf',
  templateUrl: 'dialog-pdf.html',
  styleUrls: ['dialog-pdf.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogPdf extends DialogGeneral {

  constructor(
    public matDialogRef: MatDialogRef<DialogPdf>,
    private http: HttpClient,
    public override dialogRef: MatDialogRef<DialogPdf>,
    @Inject(MAT_DIALOG_DATA)
    public data: DialogPdfData) {
    super(matDialogRef);
  }

  downloadPdf(filename, file, isBase64 = false) {
    if (isBase64) {
      var sampleArr = base64ToArrayBuffer(file);
      saveByteArray(this.data.fileName, sampleArr);
    } else {
      this.http.get(file, { responseType: 'arraybuffer' })
        .subscribe(function (data) {
          saveByteArray(filename, data);
        });
    }
  }

}
