import { Component, Inject, Input, OnInit, PLATFORM_ID, SimpleChange, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'src/app/theme/components/swiper/swiper.module';
import { AppSettings, Settings } from '../../app.settings';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { Property } from 'src/app/app.models';
import { AccountsLoginRes } from 'src/app/components/interfaces/CRUDL/read/AccountsLoginRes';
import { ListTilSoluEignirRes } from 'src/app/components/interfaces/responses/List/listTilSoluEignirRes';
import { FasteignService } from 'src/app/components/services/api/fasteign/fasteign.service';
import { NotandiService } from 'src/app/components/services/api/notandi/notandi.service';
import { opidHusString } from 'src/app/helpers/opidHus';
import { formatPriceString } from 'src/app/helpers/thousandSeparator';
import { environment } from 'src/environments/environment';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss']
})
export class PropertyItemComponent implements OnInit {
  @Input() fasteign: ListTilSoluEignirRes;
  @Input() property: Property;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false;
  @Input() fullWidthPage: boolean = true;
  public column: number = 4;
  environment = environment;

  userDetails: AccountsLoginRes;

  opidHusFilterDate: Date;
  public isBrowser: boolean;
  public isServer: boolean;


  // public address:string;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  public settings: Settings;
  constructor(public appSettings: AppSettings, public appService: AppService, private notandiService: NotandiService, @Inject(PLATFORM_ID) platformId: Object, private fasteignService: FasteignService, private snackBar: MatSnackBar) {
    this.settings = this.appSettings.settings;
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    if (this.isBrowser) {
      try {
        this.notandiService.userDetails.subscribe(userDetails => this.userDetails = userDetails);
      } catch { }
    }
  }

  ngOnInit() {
    var now = new Date();
    now.setHours(now.getHours() + 1);
    this.opidHusFilterDate = now;
  }

  formatDate(date: Date) {
    return moment(date).format('DD.MM.YYYY, [kl.] HH:mm');
  }

  formatVerd(numberString: string) {
    return formatPriceString(numberString.toString());
  }

  ngAfterViewInit() {
    this.initCarousel();
    this.config = {
      observer: false,
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: true,
      lazy: {
        loadPrevNext: true, // pre-loads the next image to avoid showing a loading placeholder if possible
        loadPrevNextAmount: 2 //or, if you wish, preload the next 2 images
      }
    };
    // this.appService.getAddress(this.property.location.lat, this.property.location.lng).subscribe(data=>{
    //   console.log(data['results'][0]['formatted_address']);
    //   this.address = data['results'][0]['formatted_address'];
    // })
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.viewColChanged) {
      this.getColumnCount(changes.viewColChanged.currentValue);
      if (!changes.viewColChanged.isFirstChange()) {
      }
    }

    for (let propName in changes) {
      // let changedProp = changes[propName];
      // if (!changedProp.isFirstChange()) {
      //   if(this.property.gallery.length > 1){
      //     this.initCarousel();
      //     this.config.autoHeight = true;
      //     this.directiveRef.update();
      //   }
      // }
    }
  }

  public getColumnCount(value) {
    if (value == 25) {
      this.column = 4;
    }
    else if (value == 33.3) {
      this.column = 3;
    }
    else if (value == 50) {
      this.column = 2
    }
    else {
      this.column = 1;
    }
  }

  public getStatusBgColor(status) {
    switch (status) {
      case 'For Sale':
        return '#558B2F';
      case 'For Rent':
        return '#1E88E5';
      case 'Open House':
        return '#009688';
      case 'No Fees':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default:
        return '#01579B';
    }
  }

  public opidHusString(fra, til) {
    return opidHusString(new Date(fra), new Date(til));
  }

  public initCarousel() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      nested: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: "slide"
    }
  }

  flytimerkjaClick(merkja: boolean) {
    if (this.userDetails) {
      if (!merkja) {
        this.snackBar.open('Fasteignin hefur verið afmerkt', '×', {
          verticalPosition: 'top',
          duration: 3000,
          direction: (this.settings.rtl) ? 'rtl' : 'ltr'
        });
        this.fasteign.flytimerkt = false;

        this.fasteignService.unMarkFavorite({
          FasteignSkraningId: this.fasteign.fasteignSkraningId
        });

      } else {
        this.snackBar.open('Fasteignin hefur verið flýtimerkt', '×', {
          verticalPosition: 'top',
          duration: 3000,
          direction: (this.settings.rtl) ? 'rtl' : 'ltr'
        });
        this.fasteign.flytimerkt = true;

        this.fasteignService.markFavorite({
          FasteignSkraningId: this.fasteign.fasteignSkraningId
        });

      }
    } else {
      window.location.href = environment.portalUrl + "/fasteign/flytimerkja/" + this.fasteign.fasteignSkraningId;
    }
  }

}
