import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'; // Import RouterModule if it's used in the component
import { SharedModule } from 'src/app/shared/shared.module';
import { NotFoundComponent } from './not-found.component'; // Adjust the path as necessary

@NgModule({
  declarations: [NotFoundComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule // Necessary for using Router features in the component
  ],
  exports: [NotFoundComponent] // Export if you need to use it in other modules
})
export class NotFoundModule { }
