import { HttpHeaders } from '@angular/common/http';
import { getCookie } from './cookies';

/**
 * Hjálpar-klasi fyrir HTTP requestur, sérstaklega á móti internal API.
 */
export class HttpRequestHelper {
    getRequestOptionsAuth() {
        try {
            const token = getCookie('accessToken');
            const requestOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': 'Bearer ' + token
                })
            };
            return requestOptions;
        } catch {
            return null;
        }
    }

    getRequestOptionsAuthFileUpload() {
        try {
            const token = getCookie('accessToken');
            const requestOptions = {
                headers: new HttpHeaders({
                    'Accept': '*/*',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Authorization': 'Bearer ' + token
                })
            };
            return requestOptions;
        } catch {
            return null;
        }
    }
}