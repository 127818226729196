import { PlatformLocation } from "@angular/common";
import { MatDialogRef } from '@angular/material/dialog';

// Notast skal við skipHistory parameter sem true ef modal á ekki að loka parent modal.

export class DialogGeneral {

    public dialogRef: MatDialogRef<any>;

    constructor(dialogRef: MatDialogRef<any>) {
        // Passar að back loki modal, þ.e. fari ekki alveg til baka um síðu.
        history.pushState(null, 'modalOpened');
        this.dialogRef = dialogRef;
    }

    close(): void {
        this.dialogRef.close();
        // Passar að eyða úr history of modal er lokað
        // history.back(); // TODO: Gæti þurft að setja þetta inn aftur!
    }

}
