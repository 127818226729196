// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clients-carousel {
  position: relative;
  padding: 8px 0;
  margin-top: 30px;
  margin-bottom: 20px;
}
.clients-carousel .swiper-container {
  padding: 8px 2px;
  margin: 0 14px;
}
.clients-carousel .swiper-container .client-item {
  height: 88px;
  display: flex;
  align-items: center;
}
.clients-carousel .swiper-container .client-item img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/clients/clients.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EACI,gBAAA;EACA,cAAA;AAER;AADQ;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AAGZ;AAFY;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAIhB","sourcesContent":[".clients-carousel{\r\n    position: relative;\r\n    padding: 8px 0;\r\n    margin-top: 30px;\r\n    margin-bottom: 20px;\r\n    .swiper-container{\r\n        padding: 8px 2px;\r\n        margin: 0 14px;\r\n        .client-item{\r\n            height: 88px;\r\n            display: flex;\r\n            align-items: center;\r\n            img{\r\n                max-width: 100%;\r\n                max-height: 100%;\r\n                margin: 0 auto;\r\n            }\r\n        }        \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
