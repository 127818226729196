import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountsLoginRes } from 'src/app/components/interfaces/CRUDL/read/AccountsLoginRes';
import { CheckBoxItem } from 'src/app/components/interfaces/items/checkBoxItem';
import { ListBaejarfelagRes } from 'src/app/components/interfaces/responses/List/listBaejarfelagRes';
import { ListPostnumerRes } from 'src/app/components/interfaces/responses/List/listPostnumerRes';
import { ListSvaediRes } from 'src/app/components/interfaces/responses/List/listSvaediRes';
import { ListTegundirHusnaedisRes } from 'src/app/components/interfaces/responses/List/listTegundirHusnaedisRes';
import { FasteignService } from 'src/app/components/services/api/fasteign/fasteign.service';
import { NotandiService } from 'src/app/components/services/api/notandi/notandi.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss'],

})
export class PropertiesSearchComponent implements OnInit {
  public isBrowser: boolean;
  public isServer: boolean;

  userDetails: AccountsLoginRes;

  @Input() isHomePage: boolean = false;
  @Input() variant: number = 1;
  @Input() vertical: boolean = false;

  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() form: UntypedFormGroup;

  // Listi yfir hluti sem hægt er að nota í filtering.
  // Sótt frá vefþjónustu.
  public postnumer: Array<ListPostnumerRes>;
  public svaedi: Array<ListSvaediRes>;
  public baejarfelog: Array<ListBaejarfelagRes>;
  public tegundirEigna: Array<ListTegundirHusnaedisRes>;

  verdOptions: Array<number> = [
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200
  ]

  // Seroskir valmöguleikar
  seroskirDropDown: CheckBoxItem[] = [
    {
      value: 'serinngangur',
      viewValue: 'Sérinngangur',
      checked: false
    },
    {
      value: 'lyfta',
      viewValue: 'Lyftuhúsnæði',
      checked: false
    },
    {
      value: 'thvottahus',
      viewValue: 'Þvottahús',
      checked: false
    },
    {
      value: 'svalir',
      viewValue: 'Svalir',
      checked: false
    },
    {
      value: 'lod',
      viewValue: 'Lóð til afnota',
      checked: false
    }
  ]

  flytimerktarSearch() {
    if (this.userDetails) {
      this.search();
    } else {
      window.location.href = environment.portalUrl + "/fasteignir/til-solu/syna-adeins-flytimerktar";
    }
  }

  public search(forceSearch = false) {
    if (!this.isHomePage || forceSearch) {
      this.onSearchClick.emit(this.form);
    }
    if (this.isHomePage) {
      // Þegar notandi er á forsíðu og er að leita eftir fasteignum til sölu þarf að passa að
      // við tökum af hakið flýtimerktar því við viljum ekki aðeins sía eftir flýtimerktum.
      this.form.get("flytimerktar").setValue(false);
      localStorage.setItem("_tilSoluFilterForm", JSON.stringify(this.form.value));
    }
  }

  public onSelectCity() {
    this.form.controls['neighborhood'].setValue(null, { emitEvent: false });
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }
  public onSelectNeighborhood() {
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }

  public getAppearance() {
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel() {
    return (this.variant == 1) ? 'always' : '';
  }

  // --------------------------- INIT ------------------------------------------------------------
  constructor(public appService: AppService, public fb: UntypedFormBuilder, public dialog: MatDialog, public fasteignService: FasteignService, @Inject(PLATFORM_ID) platformId: Object, private notandiService: NotandiService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    if (this.isBrowser) {
      try {
        this.notandiService.userDetails.subscribe(userDetails => this.userDetails = userDetails);
      } catch { }
    }
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.saekjaTegundirEigna();
      this.saekjaPostnumer();
      this.saekjaSvaedi();
      this.saekjaBaejaerfelag();
    }
  }

  // Passar að haka í rétt checkbox varðandi póstnúmer, bæjarfélag og svæði.
  // Endar á því að filtera þær færslur sem á að sýna.
  // Ef ekkert póstnúmer er valið er tekið út UI indicator fyrir svæði filtering.
  postnumerClick(i) {
    this.postnumer[i].checked = !this.postnumer[i].checked;

    // Ef eitthvað póstnumer er valið undir ákveðnum bæ
    if (this.postnumer.filter(p => p.heiti == this.postnumer[i].heiti).some(x => x.checked)) {
      // Ef ekki öll póstnumer eru merkt undir ákveðnum bæ, þó eitthvað.
      this.baejarfelog[this.baejarfelog.findIndex(x => x.heiti == this.postnumer[i].heiti)].indeterminateChecked = true;
      this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.postnumer[i].svaedi)].indeterminateChecked = true;
      // Ef öll póstnúmer undir ákveðnum bæ eru merkt
      if (this.postnumer.filter(p => p.heiti == this.postnumer[i].heiti).every(x => x.checked)) {
        // Merki bæinn sem merktann
        this.baejarfelog[this.baejarfelog.findIndex(x => x.heiti == this.postnumer[i].heiti)].indeterminateChecked = false;
        this.baejarfelog[this.baejarfelog.findIndex(x => x.heiti == this.postnumer[i].heiti)].checked = true;
        // Ef allir bæirnir undir svæðinu eru merktir, merki þá líka svæðið
        if (this.baejarfelog.filter(x => x.svaedi == this.postnumer[i].svaedi).every(x => x.checked)) {
          this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.postnumer[i].svaedi)].indeterminateChecked = false;
          this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.postnumer[i].svaedi)].checked = true;
        }
      }
    }
    // Ef ekkert póstnúmer er valið undir ákveðnum bæ þá afhökum við bæinn.
    else {
      this.baejarfelog[this.baejarfelog.findIndex(x => x.heiti == this.postnumer[i].heiti)].indeterminateChecked = false;
      this.baejarfelog[this.baejarfelog.findIndex(x => x.heiti == this.postnumer[i].heiti)].checked = false;
      if (this.baejarfelog.filter(x => x.svaedi == this.postnumer[i].svaedi).every(x => !x.checked)) {
        this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.postnumer[i].svaedi)].checked = false;
        this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.postnumer[i].svaedi)].indeterminateChecked = false;
      }
    }
    this.postnumer[i].checked = !this.postnumer[i].checked;
  }

  // Kallað í þegar valið er tiltekið bæjarfélag (checkbox). Annað hvort af eða án.
  baejarfelagClick(e, i) {
    // Kemur í veg fyrir að toggle opnist/lokist þegar verið er að velja.
    e.stopPropagation();

    this.baejarfelog[i].checked = !this.baejarfelog[i].checked;

    // Merki öll póstnúmer m.v. hvort að bær sé valinn eða ekki.
    for (let y = 0; y < this.postnumer.length; y++) {
      if (this.postnumer[y].heiti == this.baejarfelog[i].heiti) {
        if (this.baejarfelog[i].checked) {
          this.postnumer[y].checked = true;
        } else {
          this.postnumer[y].checked = false;
        }
      }
    }

    // Ef einhver bær er valinn undir ákveðnu svæði
    if (this.baejarfelog.filter(b => b.svaedi == this.baejarfelog[i].svaedi).some(x => x.checked)) {
      this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.baejarfelog[i].svaedi)].indeterminateChecked = true;
      // Ef allir bæir eru valdir undir ákveðnu svæði
      if (this.baejarfelog.filter(b => b.svaedi == this.baejarfelog[i].svaedi).every(x => x.checked)) {
        // Kanna einnig hvort það sé búið að haka í öll póstnúmer
        let merkjaSvaedi = true;
        for (let y = 0; y < this.postnumer.length; y++) {
          if (this.postnumer.filter(b => b.svaedi == this.baejarfelog[i].svaedi).some(x => !x.checked)) {
            merkjaSvaedi = false;
          }
        }
        if (merkjaSvaedi) {
          // Merki svæðið sjálf
          this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.baejarfelog[i].svaedi)].indeterminateChecked = false;
          this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.baejarfelog[i].svaedi)].checked = true;
        } else {
          this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.baejarfelog[i].svaedi)].checked = false;
        }
      }
      // Ef ekki allir bæir undir ákveðnu svæði eru merktir
      else {
        // Passa að merkja ekki svæðið sjálft, en að það sé eitthvað undir því.
        this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.baejarfelog[i].svaedi)].checked = false;
      }
    }
    // Ef enginn bær er valinn undir ákveðnu svæði
    else {
      // Passa að merkja ekki svæðið sjálft
      this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.baejarfelog[i].svaedi)].indeterminateChecked = false;
      this.svaedi[this.svaedi.findIndex(x => x.svaedi == this.baejarfelog[i].svaedi)].checked = false;
    }
    this.baejarfelog[i].checked = !this.baejarfelog[i].checked;
  }

  // Kallað í þegar svæði er valið.
  svaediClick(e, i) {
    // Kemur í veg fyrir að toggle opnist/lokist þegar verið er að velja.
    e.stopPropagation();

    this.svaedi[i].checked = !this.svaedi[i].checked;

    for (let y = 0; y < this.baejarfelog.length; y++) {
      if (this.baejarfelog[y].svaedi == this.svaedi[i].svaedi) {
        if (this.svaedi[i].checked) {
          this.baejarfelog[y].checked = true;
          this.baejarfelog[y].indeterminateChecked = false;
          for (let k = 0; k < this.postnumer.length; k++) {
            if (this.postnumer[k].heiti == this.baejarfelog[y].heiti) {
              this.postnumer[k].checked = true;
            }
          }
        } else {
          this.baejarfelog[y].checked = false;
          this.baejarfelog[y].indeterminateChecked = false;
          for (let k = 0; k < this.postnumer.length; k++) {
            if (this.postnumer[k].heiti == this.baejarfelog[y].heiti) {
              this.postnumer[k].checked = false;
              this.postnumer[k].indeterminateChecked = false;
            }
          }
        }
      }
    }
    this.svaedi[i].checked = !this.svaedi[i].checked;
  }

  // -----------------------------------------------------------------------------------------------------
  // -------------------------------- HTTP Köll ----------------------------------------------------------

  saekjaTegundirEigna() {
    this.fasteignService.saekjaTegundirHusnaedis().subscribe(res => {
      this.tegundirEigna = res.filter(x => x.tegundHusnaedis != null && x.tegundHusnaedis != "");
    });
  }

  saekjaPostnumer() {
    this.fasteignService.listPostnumer().subscribe(res => {
      this.postnumer = res;
    });
  }

  saekjaSvaedi() {
    this.fasteignService.listSvaedi().subscribe(res => {
      this.svaedi = res.filter(x => x.svaedi.length > 0);
    });
  }

  saekjaBaejaerfelag() {
    this.fasteignService.listBaejarfelag().subscribe(res => {
      this.baejarfelog = res;
    });
  }

}
