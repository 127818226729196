import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ExposedListTilkynningarReq } from 'src/app/components/interfaces/requests/Exposed/exposedListTilkynningarReq';
import { ExposedTilkynningarMerkjaSemOpnaReq } from 'src/app/components/interfaces/requests/Exposed/exposedTilkynningarMerkjaSemOpnaReq';
import { ListTilkynningarRes } from 'src/app/components/interfaces/responses/List/listTilkynningarRes';
import { ReadFjoldiTilkynningaRes } from 'src/app/components/interfaces/responses/Read/readFjoldiTilkynningaRes';
import { HttpRequestHelper } from 'src/app/helpers/httpRequestHelper';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../../system/alerts/alerts.service';
import { LoaderService } from '../../system/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class TilkynningarService {

  private bhTilkynningarCounter = new BehaviorSubject(0);
  /**
   * Heldur utan um login token
   */
  tilkynningarCounter = this.bhTilkynningarCounter.asObservable();

  changeTilkynningarCounter(counter: number) {
    this.bhTilkynningarCounter.next(counter);
    const self = this;
  }

  private httpRequestHelper = new HttpRequestHelper();

  constructor(private http: HttpClient, private loaderService: LoaderService, private alertsService: AlertsService, private router: Router) { }

  /**
   *
   * @param runner Segir til um hvort kalla eigi í fjölda tilkynninga á 10 sec fresti.
   */
  public fjoldiTilkynninga(): Observable<ReadFjoldiTilkynningaRes> {
    let subject = new Subject<ReadFjoldiTilkynningaRes>();
    this.http.get(environment.localApi + 'tilkynningar/fjoldi/read', this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
        this.changeTilkynningarCounter(res.returnItem.totalCount);
      },
      (error) => {
        console.log(error);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public merkjasemopna(req: ExposedTilkynningarMerkjaSemOpnaReq): Observable<null> {
    let subject = new Subject<null>();
    this.http.get(environment.localApi + 'tilkynningar/merkjaopna/' + req.TilkynningId, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
      },
      (error) => {
        console.log(error);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public merkjaAllarSemOpnar(): Observable<null> {
    let subject = new Subject<null>();
    this.http.get(environment.localApi + 'tilkynningar/merkjaopna/all', this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
      },
      (error) => {
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        } else {
        }
        console.log(error);
      }
    ).add(() => {
    });
    return subject.asObservable();
  }

  public listaTilkynningar(req: ExposedListTilkynningarReq): Observable<Array<ListTilkynningarRes>> {
    let subject = new Subject<Array<ListTilkynningarRes>>();
    this.http.post(environment.localApi + 'tilkynningar/lista', req, this.httpRequestHelper.getRequestOptionsAuth()).subscribe(
      (res: any) => {
        subject.next(res.returnItem);
      },
      (error) => {
        if (error.status == 429) {
          this.alertsService.changealertHeaderMessageFooter("Of margar fyrirspurnir sendar!", "Öll misnotkun er skráð og leiðir til brottvísunar.", error.error);
        } else {
        }
        this.alertsService.changealertHeaderMessageFooter(error.error.errorItem.friendlyMessage, error.error.errorItem.message, "");
      }
    ).add(() => {
    });
    return subject.asObservable();
  }
}
