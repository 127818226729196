import { environment } from 'src/environments/environment';
import { Menu } from './menu.model';

export const horizontalMenuItems = [
  new Menu(1, 'NAV.FORSIDA', '/', null, null, false, 0),
  new Menu(2, 'NAV.FASTEIGNIR', null, null, null, true, 0),
  new Menu(21, 'NAV.SKRA_FASTEIGN', null, environment.portalUrl + "/selja/skra-fasteign", null, false, 2),
  new Menu(22, 'NAV.TIL_SOLU', '/fasteignir/til-solu', null, null, false, 2),
  // new Menu(24, 'NAV.KAUPSAGA_FASTEIGNA', '/fasteignir/kaupsaga', null, null, false, 2),
  new Menu(3, 'NAV.UM_OKKUR', '/um-okkur', null, null, false, 0),
  // new Menu(31, 'NAV.ALMENNT', '/um-okkur', null, null, false, 3),
  // new Menu(32, 'NAV.VIRKNI_KERFISINS', '/virkni-kerfisins', null, null, false, 3),
  // new Menu(34, 'NAV.FYRIR_FJOLMIDLA', '/fyrir-fjolmidla', null, null, false, 3),
  // new Menu(33, 'NAV.NOTENDASKILMALAR', '/notendaskilmalar', null, null, false, 3),
  // new Menu(35, 'NAV.FYRIRSPURN_EFTIRLITSNEFNDAR', '/eftirlitsnefnd-fasteignasala', null, null, false, 3),
  new Menu(4, 'NAV.GJALDSKRA', '/gjaldskra', null, null, false, 0),
  new Menu(5, 'NAV.HAFA_SAMBAND', '/hafa-samband', null, null, false, 0)
]

export const verticalMenuItems = [
  new Menu(1, 'NAV.FORSIDA', '/', null, null, false, 0),
  new Menu(2, 'NAV.FASTEIGNIR', null, null, null, true, 0),
  new Menu(21, 'NAV.SKRA_FASTEIGN', null, environment.portalUrl + "/selja/skra-fasteign", null, false, 2),
  new Menu(22, 'NAV.TIL_SOLU', '/fasteignir/til-solu', null, null, false, 2),
  // new Menu(24, 'NAV.KAUPSAGA_FASTEIGNA', '/fasteignir/kaupsaga', null, null, false, 2),
  new Menu(3, 'NAV.UM_OKKUR', '/um-okkur', null, null, false, 0),
  // new Menu(31, 'NAV.ALMENNT', '/um-okkur', null, null, false, 3),
  // new Menu(32, 'NAV.VIRKNI_KERFISINS', '/virkni-kerfisins', null, null, false, 3),
  // new Menu(34, 'NAV.FYRIR_FJOLMIDLA', '/fyrir-fjolmidla', null, null, false, 3),
  // new Menu(33, 'NAV.NOTENDASKILMALAR', '/notendaskilmalar', null, null, false, 3),
  // new Menu(35, 'NAV.FYRIRSPURN_EFTIRLITSNEFNDAR', '/eftirlitsnefnd-fasteignasala', null, null, false, 3),
  new Menu(4, 'NAV.GJALDSKRA', '/gjaldskra', null, null, false, 0),
  new Menu(5, 'NAV.HAFA_SAMBAND', '/hafa-samband', null, null, false, 0)
]
