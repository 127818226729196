import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { GoogleMapsModule } from '@angular/google-maps';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { InputFileConfig, InputFileModule } from './theme/components/input-file/input-file.module';
const config: InputFileConfig = {
  fileAccept: '*'
};

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.fileServiceUrl + '/i18n/', '.json');
}

import { OverlayContainer } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';
import { AppComponent } from './components/app/app.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { PagesComponent } from './components/pages/pages.component';
import { DialogPdf } from './dialogs/dialog-pdf/dialog-pdf';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { NotFoundModule } from './pages/not-found/NotFoundModule';
import { SharedModule } from './shared/shared.module';
import { ContactsComponent } from './theme/components/contacts/contacts.component';
import { CurrencyComponent } from './theme/components/currency/currency.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { SocialIconsComponent } from './theme/components/social-icons/social-icons.component';
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
import { Toolbar2Component } from './theme/components/toolbar2/toolbar2.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    UserMenuComponent,
    CurrencyComponent,
    SocialIconsComponent,
    ContactsComponent,
    Toolbar1Component,
    Toolbar2Component,
    HorizontalMenuComponent,
    VerticalMenuComponent,
    FooterComponent,
    LockScreenComponent,
    CookiePolicyComponent,
    DialogPdf
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    NotFoundModule,
    HttpClientModule,
    GoogleMapsModule,
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgProgressModule,
    NgProgressHttpModule,
    InputFileModule.forRoot(config),
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    AppSettings,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
