import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AccountsLoginRes } from 'src/app/components/interfaces/CRUDL/read/AccountsLoginRes';
import { NotandiService } from 'src/app/components/services/api/notandi/notandi.service';
import { firstAndMiddleNameUser } from 'src/app/helpers/firstAndMiddleNameUser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {

  public isBrowser: boolean;
  public isServer: boolean;

  portalUrl = environment.portalUrl;

  userDetails: AccountsLoginRes;

  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appService: AppService, @Inject(PLATFORM_ID) private platformId: Object, private notandiService: NotandiService) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
    
    if (this.isBrowser) {
      try {
        this.notandiService.userDetails.subscribe(userDetails => this.userDetails = userDetails);
      } catch { }
    }
   }

  ngOnInit() { }

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }

  firstAndMiddleNameUser(name){
    return firstAndMiddleNameUser(name);
  }
}
